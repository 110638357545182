import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router";
import { BgDefault, Header, Sitemap } from "./Components";
import DB from "./DB";

function Lookup() {
  const db = useContext(DB);
  const { register, handleSubmit } = useForm();
  const Url = null;
  const [redirect, setRedirect] = useState();
  const [redirectProps, setRedirectProps] = useState();
  const [loading, setLoading] = useState(false);

  const submitHandler = (d) => {
    setLoading(true);
    db.getQRCodeDoc(d.qrCode.substring(4, 6), d.qrCode.split("/").pop(), (q) => {
      console.log(q);
      db.getDatabase(`users/${q.params.user}`, (u) => {
        console.log(u);
        setRedirectProps({ user: u, purpose: q.purpose });
        setRedirect(q.purpose.includes("Certificate") ? "/certificate" : "/renderdoc");
      });
    });
    db.getStorageItem("resources/" + d.qrCode.split("/")[3] + ".pdf", (url) => (window.location.href = url));
  };
  if (redirect) return <Redirect push to={{ pathname: redirect, state: redirectProps }} />;

  return (
    <div>
      <BgDefault />
      <Header />
      <h3 className="h3-title">
        <b>DOCUMENT LOOKUP</b>
      </h3>
      <p style={{ width: "65%", margin: "auto" }}>
        All Next Up Official Documents are equiped with a universal Standard Document Identification Format (SDIF) code. If you have received an
        official document from Next Up you may scan the code and enter its contents below to verify the authenticity of the document.
      </p>
      <hr style={{ width: "60%" }} />
      <br />
      <Form className="lookup-container" onSubmit={handleSubmit(submitHandler)}>
        <h5 style={{ letterSpacing: "1px" }}>
          <b>DOCUMENT QR DATA</b>
        </h5>
        <br />
        {/* value="1.0/CR-000084/20210924/EXy1dcY3" */}
        <Form.Control
          type="text"
          name="qrCode"
          placeholder="1.0/XX-000000/12345678/XXXXXXXXXX"
          className="textfield field"
          ref={register({ required: true })}
        />
        <br />
        <Button type="submit" className="submitLogin" style={{ letterSpacing: "1px" }}>
          {loading ? <Spinner as="span" animation="border" role="status" size="sm" /> : <div>LOOKUP</div>}
        </Button>
        <br />
        {Url && (
          <a className="subbtn" href={Url} onClick={() => (window.location = Url)} download="document1">
            Download
          </a>
        )}
      </Form>
      <br />
      <br />
      <Sitemap />
    </div>
  );
}

export default Lookup;
