import ReactStars from "react-rating-stars-component";
import { Header, Sitemap } from "./Components";
import { CircleContainer } from "./Circles";
import React, { useEffect, useState, useContext } from "react";
import "./Components.css";
import DB from "./DB";
import "./Cards.css";
import "./Circles.css";
import { Redirect } from "react-router";
import moment from "moment";

function ML(props) {
  const db = useContext(DB);

  const [ml, setml] = useState();
  const [redirect, setRedirect] = useState();
  const [redirectProps, setRedirectProps] = useState();
  // const COLORS = ['#1c56b8', '#9257b2'];
  // const BORDER = ['10px solid #2875f7', '10px solid #c674f2'];
  const [useDarkMode, setDarkMode] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addListener((e) => setDarkMode(e.matches));
  }, []);

  useEffect(() => {
    db.getDatabase("/courses/ml", (data) => {
      console.log("data", data);
      console.log("event", data.events[0].title);
      setml(data);
    });
  }, [db]);

  // const get = async () => {
  // 	console.log('get')
  // 	console.log(await db.getCourse('ml'))
  // }
  if (redirect)
    return <Redirect push to={{ pathname: redirect, state: redirectProps }} />;

  let borderColour = ["#9257b2", "#1c56b8"];
  let upcomingTop = ["#2875f7", "#c674f2"];
  let upcomingBg = ["#1c56b8", "#9257b2"];
  let icon = ["\uf559", "\uf19d"];

  return (
    <div className="arMainDiv">
      <Header />
      {ml && (
        <div>
          <div
            className="mainCard"
            style={{
              backgroundImage: `url(${require("./images/ml/bg.png")})`,
              backgroundAttachment: "fixed",
              backgroundPosition: "0% 3%",
              backgroundSize: "120%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src={require("./images/ml/logo.png")}
              className="arTitle fadeBottom"
              alt="Fundamental Machine Learning "
            />
          </div>
          <br />

          <div className="info">
            <h3>{ml.description[0].title}</h3>
            <span>{ml.description[0].content[0]}</span>
            <hr />
            <h3>{ml.description[1].title}</h3>
            <span>{ml.description[1].content[0]}</span>
          </div>

          {borderColour.map((item, i) => (
            <div className="card-container" key={i}>
              <div class="flip-card">
                <h3>
                  <b className="fas">{`${icon[i]}`}</b> {ml.cards[i].title}
                </h3>
                <div class="flip-card-inner">
                  <div
                    class="flip-card-front"
                    style={{ borderTop: ` 9px solid ${item}` }}
                  >
                    <span>
                      {ml.cards[i].content.map((item, i) => (
                        <li>{item}</li>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div style={{ marginTop: 70, marginBottom: 10 }}>
            <div
              className="award"
              style={{
                backgroundImage: `url(${useDarkMode ? require("./images/award-white.png") : require("./images/award.png")})`,
              }}
            >
              <h3>NVIDIA</h3>
              <div>Worked at NVIDIA AI Division</div>
            </div>
            <div
              className="award"
              style={{
                backgroundImage: `url(${useDarkMode ? require("./images/award-white.png") : require("./images/award.png")})`,
              }}
            >
              <h3>RESEARCH</h3>
              <div>Author of two research papers in AI & ML</div>
            </div>
            <div
              className="award"
              style={{
                backgroundImage: `url(${useDarkMode ? require("./images/award-white.png") : require("./images/award.png")})`,
              }}
            >
              <h3>HEAD</h3>
              <div>Former head of AI at TRF Robosoft</div>
            </div>
          </div>
          <div class="upcomingEvents">
            <h3>
              <b className="fas">{"\uf1da"}</b>&nbsp;&nbsp;Upcoming Events
            </h3>
          </div>

          {upcomingTop.map((item, i) => (
            <div class="upcoming">
              <div
                className="eventTitle"
                style={{
                  borderTop: `10px solid ${item}`,
                  background: `${upcomingBg[i]}`,
                }}
              >
                <div className="event">
                  <h3>
                    <b className="fas">{`${icon[i]}`}</b> {ml.events[i].title}
                  </h3>
                </div>
                <div className="dateBox">
                  <span>
                    <b className="fas">{"\uf073"}</b> &nbsp;{" "}
                    {moment(ml.events[i].date.start).format("MMM D")} -{" "}
                    {moment(ml.events[i].date.end).format("D, YYYY")}
                  </span>
                </div>
              </div>
              <div className="eventDetail">
                <div>
                  <span>
                    <b className="fas">{"\uf3c5"}</b>&nbsp;{" "}
                    {ml.events[i].location.address}
                  </span>
                </div>
                <div className="publicEvent">
                  <span>
                    <b className="fas">{"\uf007"}</b>&nbsp; {ml.events[i].entry}
                  </span>
                </div>
                <div style={{ display: "inline-block" }}>
                  <span>
                    <b className="fas">{"\uf0c0"}</b>&nbsp; {ml.events[i].seats}
                  </span>
                </div>
                <div>
                  <span>
                    <b className="fas">{"\uf017"}</b>&nbsp; 8:30 AM - 4:30 PM
                  </span>
                </div>
              </div>
              <div
                className="registerButton"
                onClick={() => {
                  setRedirect("/register");
                  setRedirectProps({
                    title: `${ml.events[i].title}`,
                    course: "ml",
                    id: 0,
                  });
                }}
              >
                REGISTER
              </div>
            </div>
          ))}

          <div class="upcomingEvents">
            <h3>
              <b className="fas">{"\uf005"}</b>&nbsp;&nbsp;Reviews
            </h3>
          </div>

          <div className="reviews">
            <CircleContainer />
            <div className="ratingContainer">
              <div className="star">
                <div className="starTitle">Overall Experience</div>
                <ReactStars
                  count={5}
                  value="4.5"
                  isHalf={true}
                  size={30}
                  edit={false}
                  activeColor="#ffd900"
                  style={{ display: "inline-block" }}
                />
                <div className="rating">4.6</div>
              </div>
              <br />
              <div className="star">
                <div className="starTitle">Knowledgability of Speaker</div>
                <ReactStars
                  count={5}
                  value="4.8"
                  isHalf={true}
                  size={30}
                  edit={false}
                  activeColor="#ffd900"
                  style={{ display: "inline-block" }}
                />
                <div className="rating">4.7</div>
              </div>
              <br />
              <div className="star">
                <div className="starTitle">
                  Program Organization & Structure
                </div>
                <ReactStars
                  count={5}
                  value="4.5"
                  isHalf={true}
                  size={30}
                  edit={false}
                  activeColor="#ffd900"
                  style={{ display: "inline-block" }}
                />
                <div className="rating">4.8</div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      )}

      <Sitemap />
    </div>
  );
}

export default ML;

// import ReactStars from "react-rating-stars-component";
// import Button from 'react-bootstrap/Button'
// import { Header, Sitemap } from './Components';
// import { CircleContainer } from './Circles';
// // import { Line, Circle } from 'rc-progress';
// import React, { useEffect, useState } from 'react';
// import './Components.css';
// import './Cards.css';
// import './Circles.css';
// import { Redirect } from 'react-router';
// import moment from 'moment';

// function ML(props) {
// 	const [ml, setml] = useState();
// 	const [redirect, setRedirect] = useState();
// 	const [redirectProps, setRedirectProps] = useState();
// 	const COLORS = ['#1c56b8', '#9257b2'];
// 	const BORDER = ['10px solid #2875f7', '10px solid #c674f2'];

// 	useEffect(() => {
// 		db.getCourse('ml', data => {
// 			console.log(data);
// 			setml(data)
// 		})
// 	}, [])

// 	if (redirect) return <Redirect push to={{ pathname: redirect, state: redirectProps }} />

// 	return (
// 		<div style={{ marginTop: '8%' }}>
// 			<Header />
// 			{
// 				ml && <div>
// 					<div className='mainCard' style={{ backgroundImage: `url(${require("./images/ml/bg.png")})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
// 						<img src={require("./images/ml/logo.png")} className='mlTitle fadeBottom' alt="Fundamental Machine Learning " />
// 					</div><br />

// 					<div class='info'>
// 						<h3>{ml.about[0].title}</h3>
// 						{ml.about[0].content[0]}
// 						{ml.about[1].content[0]}
// 						<br />
// 					</div>

// 					<div className='card-container'>
// 						<div class="flip-card">
// 							<div class="flip-card-inner">
// 								<div class="flip-card-front" style={{ borderTop: '9px solid #1c56b8' }}>
// 									<p><b className="fas">{"\uf19d"}</b> {ml.cards[0].title}</p>
// 								</div>
// 								<div class="flip-card-back">
// 									<ul>
// 										{ml.cards[0].content.map((item, i) => (
// 											<li>{item}</li>
// 										))}
// 									</ul>
// 								</div>
// 							</div>
// 						</div>
// 						<div class="flip-card">
// 							<div class="flip-card-inner">
// 								<div class="flip-card-front" style={{ borderTop: '9px solid  #9257b2' }}>
// 									<p><b className="fas">{"\uf559"}</b> {ml.cards[1].title}</p>
// 								</div>
// 								<div class="flip-card-back">
// 									<ul>
// 										{
// 											ml.cards[1].content.map((item, i) => (
// 												<li>{item}</li>
// 											))
// 										}
// 									</ul>
// 								</div>
// 							</div>
// 						</div>
// 					</div><br />

// 					<div>
// 						<div className='award' style={{ backgroundImage: `url(${require("./images/award.png")})` }}>
// 							<h3>NVIDIA</h3>
// 							<div>Worked at NVIDIA AI Division</div>
// 						</div>
// 						<div className='award' style={{ backgroundImage: `url(${require("./images/award.png")})` }}>
// 							<h3>RESEARCH</h3>
// 							<div>Author of two research papers in AI & ML</div>
// 						</div>
// 						<div className='award' style={{ backgroundImage: `url(${require("./images/award.png")})` }}>
// 							<h3>HEAD</h3>
// 							<div>Former head of AI at TRF Robosoft</div>
// 						</div>
// 					</div>

// 					<div style={{ display: 'inline-flex', width: '100%' }}>
// 						<CircleContainer />
// 						<div className='ratingContainer' >
// 							<div className='star'>
// 								<div className='starTitle'>Overall Experience</div>
// 								<ReactStars count={5} value='4.5' isHalf={true} size={30} edit={false} activeColor="#ffd900" style={{ display: 'inline-block' }} />
// 								<div className='rating'>4.6</div>
// 							</div><br />
// 							<div className='star'>
// 								<div className='starTitle'>Knowledgability of Speaker</div>
// 								<ReactStars count={5} value='4.8' isHalf={true} size={30} edit={false} activeColor="#ffd900" style={{ display: 'inline-block' }} />
// 								<div className='rating'>4.7</div>
// 							</div><br />
// 							<div className='star'>
// 								<div className='starTitle'>Program Organization & Structure</div>
// 								<ReactStars count={5} value='4.5' isHalf={true} size={30} edit={false} activeColor="#ffd900" style={{ display: 'inline-block' }} />
// 								<div className='rating'>4.8</div>
// 							</div>
// 						</div>
// 					</div><br /><br />

// 					<h2 class='upcomingEvents'><b className="fas">{"\uf1da"}</b>&nbsp;&nbsp;Upcoming Events</h2>

// 					{ml.events.map((item, i) => (
// 						<div class='upcoming'>
// 							<div className='eventTitle' style={{ borderTop: BORDER[i], background: COLORS[i] }}>
// 								<div className='event educational'><b className="fas">{"\uf19d"}</b>&nbsp; {item.title}</div>
// 								<div className='dateBox shadow'><b className="fas">{"\uf073"}</b>&nbsp;{moment(item.date.start).format("DD MMM YY")} - {moment(item.date.end).format("DD MMM YY")}</div>
// 							</div>
// 							<div className='size18'>
// 								<div ><b className="fas">{"\uf3c5"}</b>&nbsp; 91springboard, 7th Block, Koramangala, Bangalore - 560095</div>
// 								<div className="publicEvent"><b className="fas">{"\uf007"}</b>&nbsp; {item.entry}</div>
// 								<div style={{ display: 'inline-block' }}><b className="fas">{"\uf0c0"}</b>&nbsp; {item.seats} Seats</div>
// 								<div><b className="fas">{"\uf017"}</b>&nbsp; 8:30 AM - 4:30 PM</div>
// 							</div>
// 							<div className='registerButton shadow' onClick={() => { setRedirect('/register'); setRedirectProps({ title: item.title, course: 'ml' ,id:i}) }}>REGISTER</div>
// 						</div>
// 					))}
// 				</div>
// 			}
// 			<Sitemap />
// 		</div>
// 	);
// }

// export default ML;
