import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convert } from "ical2json";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Redirect } from "react-router";
import {
  AgendaModal,
  Circle,
  DashboardNavBar,
  DecryptModal,
  QrCodeModal,
  Sitemap,
} from "./Components";
import "./Dashboard.css";
import DB from "./DB";

function AdminConsole(props) {
  const db = useContext(DB);

  const [user, setUser] = useState(props.location.state);
  const [documents, setDocuments] = useState();

  const [calendarItems, setCalendarItems] = useState([]);
  const [redirect, setRedirect] = useState();

  const [calendarSelectedDate, setSelectedDate] = useState(new Date());

  const [showAgendaModal, setShowAgendaModal] = useState(false);

  const [userData, setUserData] = useState();

  const [passwords, setPasswords] = useState();

  const [documentsShowMore, setDocumentsShowMore] = useState(false);
  const [usersShowMore, setUsersShowMore] = useState(false);

  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [qrTitleColor, setQrTitleColor] = useState();

  const [showPasswordModal, setPasswordModal] = useState(false);
  const [selectedPassword, setSelectedPassword] = useState();

  useEffect(() => {
    if (!user) {
      db.loginFetch((_user) => {
        if (_user && _user.email.split("@")[1] === "nextup.co.in")
          setUser(_user);
        else window.location.href = "/login";
      });
    } else {
      const parseCalendarItems = (data) => {
        fetch(data)
          .then((p) => p.text())
          .then((res) =>
            setCalendarItems((ci) => {
              let newCalItems = [
                ...ci,
                ...convert(res).VCALENDAR[0].VEVENT.map((c) => {
                  return {
                    title: c.SUMMARY,
                    start: c.DTSTART,
                    end: c.DTEND,
                    url:
                      c.DESCRIPTION.includes("https://") &&
                      "https://" +
                        c.DESCRIPTION.split("https://")[1].split("\\n", 1)[0],
                  };
                }),
              ];
              return newCalItems.sort(
                (a, b) => moment(a.start).unix() - moment(b.start).unix(),
              );
            }),
          );
      };
      db.getDatabase("/documents", setDocuments);
      db.getDatabase(`/users`, setUserData);
      db.getDatabase(`/passwords`, setPasswords);
      db.getStorageItem("calendars/ML04 - Sessions.ics", parseCalendarItems);
      db.getStorageItem("calendars/ML04 - Tasks.ics", parseCalendarItems);
    }
  }, [user, db]);

  const downloadDocument = (assign) => {
    assign.forEach((a) => {
      a.url.includes("drive.google.com")
        ? window.open(a.url, "_blank")
        : db.getStorageItem(a.url, (file) => window.open(file, "_blank"));
    });
  };

  const openCalendars = () => {
    window.open(
      "https://calendar.google.com/calendar/embed?src=c_0lbq0flg0r0gkcs15068uq44oo%40group.calendar.google.com&ctz=Asia%2FKolkata",
      "_blank",
    );
    window.open(
      "https://calendar.google.com/calendar/embed?src=c_2ei52594ibooqvr0pkdqvv5qvs%40group.calendar.google.com&ctz=Asia%2FKolkata",
      "_blank",
    );
  };

  const getCalendarItemColor = (title, isLive) => {
    if (title.includes("Day")) return isLive ? "green" : "grey";
    else if (title.includes("Crash")) return "grey";
    else if (
      title.includes("Briefing") ||
      title.includes("Keynote") ||
      title.includes("Valedictory")
    )
      return "green";
    else if (title.includes("Task")) return "orange";
    else return "red";
  };

  const getDocTypeFullName = (d) => {
    switch (d) {
      case "BR":
        return "Board Resolution";
      case "CR":
        return "Certificate";
      case "HR":
        return "Human Resource";
      case "PR":
        return "Public Relations";
      case "QT":
        return "Quotation";
      case "EC":
        return "Employment Contract";
      case "EN":
        return "Executive Notice";
      case "LT":
        return "Letter";
      case "FN":
        return "Financial";
      default:
        return "";
    }
  };

  const getDocTypeColor = (d) => {
    switch (d) {
      case "BR":
        return "red";
      case "CR":
        return "orange";
      case "HR":
        return "brown";
      case "PR":
        return "hotpink";
      case "QT":
        return "purple";
      case "EC":
        return "green";
      case "EN":
        return "black";
      case "LT":
        return "skyblue";
      case "FN":
        return "orange";
      default:
        return "black";
    }
  };

  const logout = () => {
    db.logout(() => {
      setRedirect("/login");
    });
  };

  if (redirect) return <Redirect to={{ pathname: redirect, state: user }} />;
  return (
    <Fragment>
      <DashboardNavBar
        user={user || { name: "" }}
        onClick={() => logout()}
        documents={documents}
        {...{ db }}
      />
      {showAgendaModal && (
        <AgendaModal
          {...{
            calendarItems,
            getCalendarItemColor,
            showAgendaModal,
            setShowAgendaModal,
          }}
        />
      )}
      {showPasswordModal && passwords && (
        <DecryptModal
          {...{
            show: showPasswordModal,
            set: setPasswordModal,
            passwords,
            setPasswords,
            selectedPassword,
            setSelectedPassword,
          }}
        />
      )}
      {showQrCodeModal && (
        <QrCodeModal
          {...{
            show: showQrCodeModal,
            documents,
            d: qrTitleColor,
            set: setShowQrCodeModal,
          }}
        />
      )}
      <div className="container" style={{ marginTop: 120 }}>
        {user ? (
          <div className="profile">
            <div
              style={{
                display: "inline-block",
                width: "9%",
                marginRight: "1%",
              }}
            >
              <Circle color="#5e09b8" icon={"\uf007"} />
            </div>

            <div className="profileName">
              <div style={{ marginBottom: "-2%", fontSize: "20px" }}>
                {user.name}
              </div>
              <div className="smallText" style={{ width: "40%" }}>
                {user.role.toUpperCase()}
              </div>
            </div>
          </div>
        ) : (
          <Spinner
            animation="border"
            style={{ marginTop: "15%", marginBottom: "15%" }}
          />
        )}
      </div>
      <h3 className="headings">Coming Up</h3>
      <div className="container">
        <div style={{ display: "inline-flex", width: "95%", flexWrap: "wrap" }}>
          <div className="nextSessions">
            {calendarItems.length > 0 ? (
              calendarItems
                .filter((c) =>
                  moment(c.start).isSame(calendarSelectedDate, "day"),
                )
                .map((item, i) => (
                  <Fragment key={i}>
                    <div
                      className="calendarInfo"
                      style={{
                        borderColor: getCalendarItemColor(
                          item.title,
                          item.url && true,
                        ),
                      }}
                    >
                      <div className="sessionTitle" style={{ fontWeight: 500 }}>
                        {item.title}
                      </div>
                      {item.url && (
                        <div
                          className="gMeetButton pressable"
                          alt=""
                          onClick={() =>
                            window.open(item.url, "_blank").focus()
                          }
                        >
                          <img
                            src={require("./images/main/meet.png")}
                            className="gMeetLogo"
                            alt=""
                          />
                        </div>
                      )}

                      <div className="smallText" style={{ width: "40%" }}>
                        {moment(item.start).format("MMM D, h:mm A")} -{" "}
                        {moment(item.end).format("h:mm A")}
                      </div>
                      <br />
                    </div>{" "}
                    <br />
                  </Fragment>
                ))
            ) : (
              <Spinner
                animation="border"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              />
            )}
          </div>
          <div className="calendarBox">
            <Calendar
              onChange={setSelectedDate}
              value={calendarSelectedDate}
              className="shadow"
            />
          </div>
        </div>
        <div
          className="pressable gCalButton"
          onClick={() => setShowAgendaModal(!showAgendaModal)}
        >
          Agenda View
        </div>
        <div className="pressable gCalButton" onClick={() => openCalendars()}>
          <img
            src={require("./images/main/cal.png")}
            className="gMeetLogo"
            style={{ float: "left", marginTop: -5 }}
            alt=""
          />
          &nbsp; &nbsp; &nbsp; Open in Google Calendar&nbsp; &nbsp; &nbsp;{" "}
          <b className="fas">{"\uf35d"}</b>
        </div>
      </div>

      <h3 className="headings">Documents</h3>
      <div className="container" style={{ alignItems: "start" }}>
        {documents ? (
          Object.keys(documents)
            .sort((a, b) => documents[b].length - documents[a].length)
            .filter((_, i) => (documentsShowMore ? true : i < 1))
            .map((d, i) => (
              <div key={i}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: 20, float: "right", marginTop: 8 }}>
                    <b
                      className="fas pressable"
                      style={{ fontSize: 20, marginRight: 32 }}
                    >
                      {"\uf002"}
                    </b>
                    <span
                      className="pressable"
                      onClick={() => {
                        setShowQrCodeModal(true);
                        setQrTitleColor([d, getDocTypeColor(d)]);
                      }}
                    >
                      <b
                        className="fas"
                        style={{ fontSize: 20, marginRight: 32 }}
                      >
                        {"\uf067"}
                      </b>
                    </span>
                    {documents[d].length} items
                  </div>
                  <span
                    style={{
                      padding: "8px 12px",
                      borderRadius: 10,
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "white",
                      background: getDocTypeColor(d),
                      float: "left",
                    }}
                  >
                    {d}
                  </span>
                  <span
                    className="vidTitle"
                    style={{ fontSize: 20, paddingTop: 8, paddingLeft: 20 }}
                  >
                    {getDocTypeFullName(d)}
                  </span>
                </div>
                <br />
                <div
                  className="sessionsContainer"
                  style={{ textAlign: "left" }}
                >
                  {documents[d]
                    .sort((a, b) => b.id - a.id)
                    .map((item, k) => (
                      <div
                        className="vid-container pressable"
                        key={k}
                        style={{
                          borderLeft: "8px solid " + getDocTypeColor(d),
                          padding: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            float: "right",
                            marginTop: 10,
                            marginRight: 5,
                          }}
                        >
                          {item.id}
                        </span>
                        <span
                          className="vidTitle"
                          style={{ marginBottom: 0, marginTop: 8 }}
                        >
                          {item.purpose}
                        </span>
                        <span
                          className="vidDescription"
                          style={{ color: "grey" }}
                        >
                          {moment(item.date, "YYYYMMDD").format("MMM D, YYYY")}
                        </span>
                      </div>
                    ))}
                </div>
                <br />
              </div>
            ))
        ) : (
          <Spinner
            animation="border"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          />
        )}
        <div
          className="vidDescription pressable"
          onClick={() => setDocumentsShowMore(!documentsShowMore)}
        >
          <FontAwesomeIcon
            icon={documentsShowMore ? faChevronCircleUp : faChevronCircleDown}
          />{" "}
          Show {!documentsShowMore ? "More" : "Less"}
        </div>
      </div>

      <h3 className="headings">Passwords</h3>
      <div className="container" style={{ alignItems: "start" }}>
        {passwords ? (
          <Fragment>
            <div style={{ textAlign: "right" }}>
              <span style={{ fontSize: 20, marginTop: 8 }}>
                <b
                  className="fas pressable"
                  style={{ fontSize: 20, marginRight: 32 }}
                >
                  {"\uf023"}
                </b>
                <b
                  className="fas pressable"
                  style={{ fontSize: 20, marginRight: 32 }}
                >
                  {"\uf002"}
                </b>
                <b
                  className="fas pressable"
                  style={{ fontSize: 20, marginRight: 32 }}
                >
                  {"\uf067"}
                </b>
                {passwords.accounts.length} items
              </span>
            </div>
            <br />
            <div className="sessionsContainer">
              {passwords.accounts.map((p, i) => (
                <div
                  className="vid-container pressable"
                  key={i}
                  style={{ borderLeft: "8px solid red", padding: 15 }}
                  onClick={() => {
                    setPasswordModal(true);
                    setSelectedPassword(i);
                  }}
                >
                  <span
                    className="vidTitle"
                    style={{ marginBottom: 0, marginTop: 8 }}
                  >
                    {p.name}
                  </span>
                  <span className="vidDescription" style={{ color: "grey" }}>
                    {p.email}
                  </span>
                </div>
              ))}
            </div>
          </Fragment>
        ) : (
          <Spinner
            animation="border"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          />
        )}
      </div>

      <h3 className="headings">Users</h3>
      <div className="container" style={{ alignItems: "start" }}>
        {userData ? (
          Object.values(userData)
            .filter((f) => f.phone !== 8971729383)
            .filter((_, i) => (usersShowMore ? true : i < 3))
            .map((u, i) => (
              <div key={i} className="vid-container pressable">
                <div className="profile">
                  <div
                    style={{
                      display: "inline-block",
                      width: "9%",
                      margin: "3%",
                    }}
                  >
                    <Circle color="#5e09b8" icon={"\uf007"} />
                  </div>

                  <div
                    className="profileName"
                    style={{ width: "100%", margin: "20px 10%" }}
                  >
                    <div style={{ marginBottom: "-2%", fontWeight: "500" }}>
                      {u.name}
                    </div>
                    <div className="smallText">{u.phone}</div>
                  </div>
                </div>
                {usersShowMore && u.progress && (
                  <div style={{ margin: "0 20px 20px 20px" }}>
                    <span className="vidTitle">
                      Audit Scores:{" "}
                      {u.progress.mcq
                        ? Object.values(u.progress.mcq).join(", ")
                        : "Not Attended"}
                    </span>
                    <span className="vidTitle">
                      Live Attendance: {u.progress.attendanceDays}
                    </span>
                    {u.progress.assignments && (
                      <Fragment>
                        <span className="vidTitle">Uploads: </span>
                        <ul style={{ paddingLeft: 8, textAlign: "left" }}>
                          {u.progress.assignments &&
                            Object.keys(u.progress.assignments).map(
                              (assign, a) => (
                                <li
                                  key={a}
                                  onClick={() =>
                                    downloadDocument(
                                      u.progress.assignments[assign],
                                    )
                                  }
                                >
                                  <span className="vidDescription">
                                    {assign}
                                  </span>
                                </li>
                              ),
                            )}
                        </ul>
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
            ))
        ) : (
          <Spinner
            animation="border"
            style={{ marginTop: "12%", marginBottom: "12%" }}
          />
        )}
        <div
          className="vidDescription pressable"
          onClick={() => setUsersShowMore(!usersShowMore)}
        >
          <FontAwesomeIcon
            icon={usersShowMore ? faChevronCircleUp : faChevronCircleDown}
          />{" "}
          Show {!usersShowMore ? "More" : "Less"}
        </div>
      </div>

      <br />
      <Sitemap />
    </Fragment>
  );
}

export default AdminConsole;
