import { Header } from "./Components";
import React from "react";

function UI() {
  return (
    <div>
      <Header />
    </div>
  );
}

export default UI;
