import React from "react";
import { BgDefault, Header, Sitemap } from "./Components";

function Contact() {
  return (
    <div>
      <BgDefault />
      <Header />
      <br />
      <h3 style={{ marginTop: 100 }}>Contact Us</h3>
      <br />
      <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
        <div>
          <h4>General & Institutional Enquiries</h4>
          <div className="vid-container" style={{ padding: 20 }}>
            <b>Next Up (Autre Learning LLP)</b> contact@next-up.in
            <br />
            <br />
          </div>
        </div>
        <div>
          <h4>Public Relations & Product Enquiries</h4>
          <div className="vid-container" style={{ padding: 20 }}>
            <b>Nishank Swamy D N</b>
            <br />
            MD, Operations
            <br />
            nishank@next-up.in
            <br />
          </div>
        </div>
      </div>
      <h3>Our Team</h3>
      <div className="vid-container" style={{ padding: 20, alignSelf: "center", justifySelf: "center" }}>
        <b>Menahi Shayan</b>
        <br />
        Chief Technology Officer & Co-Founder
        <br />
        shayan@next-up.in
        <br />
      </div>
      <div className="vid-container" style={{ padding: 20 }}>
        <b>Nishank Swamy D N</b>
        <br />
        MD, Operations (Co-Founder)
        <br />
        nishank@next-up.in
        <br />
      </div>
      <br />
      <div className="vid-container" style={{ padding: 20, alignSelf: "center", justifySelf: "center" }}>
        <b>Vamshi Prasad</b>
        <br />
        Technical Consultant
        <br />
        vamshi@next-up.in
        <br />
      </div>
      <div className="vid-container" style={{ padding: 20, alignSelf: "center", justifySelf: "center" }}>
        <b>Param Mirani</b>
        <br />
        Technical Consultant
        <br />
        param@next-up.in
        <br />
      </div>
      <br />
      <div className="vid-container" style={{ padding: 20 }}>
        <b>Navneeth Rans</b>
        <br />
        Marketing Associate
        <br />
        navneeth@next-up.in
        <br />
      </div>
      <div className="vid-container" style={{ padding: 20 }}>
        <b>Ramakrishna D</b>
        <br />
        Human Resources & Finance
        <br />
        ramakrishna@next-up.in
        <br />
      </div>
      <h3>Corporate Office</h3>
      <iframe
        title="Corporate Office Address"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.611873831801!2d77.60744531482152!3d12.932648990881471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDU1JzU3LjUiTiA3N8KwMzYnMzQuNyJF!5e0!3m2!1sen!2sus!4v1580394027212!5m2!1sen!2sus"
        width="650"
        height="200"
        class="map"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
      ></iframe>
      <br />
      Autre Learning LLP, No. 27/1, 4th Cross, 3rd Main,
      <br />
      Venkateshwara Layout, SG Palya, Bangalore - 560029 <br />
      <br />
      <b>Working Hours</b>
      <br />
      Mon - Fri, 9:00 AM to 7:00 PM
      <br />
      <Sitemap />
    </div>
  );
}

export default Contact;
