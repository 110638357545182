import {
  faAngleDown,
  faAngleUp,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convert } from "ical2json";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import Calendar from "react-calendar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Redirect } from "react-router";
import "./Calendar.css";
import {
  AgendaModal,
  Circle,
  DashboardNavBar,
  DragDropModal,
  McqModal,
  Sitemap,
  TermsModal,
  VidPlayerModal,
} from "./Components";
import "./Dashboard.css";
import DB from "./DB";

function Dashboard(props) {
  const db = useContext(DB);
  const [user, setUser] = useState(props.location.state);

  const [showRefer, setShowRefer] = useState(true);
  const [calendarItems, setCalendarItems] = useState([]);
  const [progress, setProgress] = useState();
  const [showArrow, setShowArrow] = useState(true);
  const [redirect, setRedirect] = useState();
  // const [redirectProps, setRedirectProps] = useState();

  const [sessions, setSessions] = useState([]);
  const [modalSession, setModalSessions] = useState();
  const [showVidModal, setShowVidModal] = useState(false);

  const [mcqModal, setMcqModal] = useState(false);
  const [mcqData, setMcqData] = useState();
  const [mcqModalTitle, setMcqModalTitle] = useState();
  // const [mcqBgIcon, setMcqBgIcon] = useState([['white',''],['white','']]);

  const [attendanceTotal, setAttendanceTotal] = useState();
  const [notifications, setNotifications] = useState();
  const [feedback, setFeedback] = useState();

  const [assignmentsModal, setAssignmentsModal] = useState(false);
  const [assignmentItem, setAssignmentItem] = useState();
  const [generalAssignments, setGeneralAssignments] = useState();
  const [calendarSelectedDate, setSelectedDate] = useState(new Date());

  const [showAgendaModal, setShowAgendaModal] = useState(false);

  const [documents, setDocuments] = useState([]);

  const sessionEnded = moment().isSameOrAfter(moment("2021-09-24 17:30")); //make dynamic

  const REFER_POPUP_TIMEOUT = 3;

  useEffect(() => {
    if (!user) {
      db.loginFetch((_user) => {
        if (_user && _user.email.split("@")[1] !== "nextup.co.in")
          setUser(_user);
        else window.location.href = "/login";
      });
    } else {
      const parseCalendarItems = (data) => {
        fetch(data)
          .then((p) => p.text())
          .then((res) =>
            setCalendarItems((ci) => {
              let newCalItems = [
                ...ci,
                ...convert(res).VCALENDAR[0].VEVENT.map((c) => {
                  return {
                    title: c.SUMMARY,
                    start: c.DTSTART,
                    end: c.DTEND,
                    url:
                      c.DESCRIPTION.includes("https://") &&
                      "https://" +
                        c.DESCRIPTION.split("https://")[1].split("\\n", 1)[0],
                  };
                }),
              ];
              return newCalItems.sort(
                (a, b) => moment(a.start).unix() - moment(b.start).unix(),
              );
            }),
          );
      };

      db.getStorageItem("calendars/ML04 - Sessions.ics", parseCalendarItems);
      db.getStorageItem("calendars/ML04 - Tasks.ics", parseCalendarItems);

      db.getDatabase("/dashboard/ml", (d) => {
        setNotifications(d.notifications);
        setFeedback(d.feedback);
        setGeneralAssignments(d.assignments);
        d.sessions.forEach((item) =>
          db.getStorageItem(item.url, (u) =>
            setSessions((s) => {
              return [...s, { ...item, url: u }];
            }),
          ),
        );
        setMcqData(d.mcq);
        setDocuments(d.documents);

        // sessions
        let totalRecorded = d.sessions.filter(
          (s) =>
            moment(s.date + " 8:30", "YYYY-MM-DD h:mm").isSameOrBefore(
              moment(),
            ) && !s.id.split("ML_V_D")[1].includes("_"),
        ).length;

        if (user && user.progress) {
          let attendanceparts = user.progress.attendanceDays.split("/");
          setAttendanceTotal({
            live: Math.round((attendanceparts[0] * 100) / attendanceparts[1]),
            recorded: Math.round(
              (user.progress.vidViewed
                ? Object.keys(user.progress.vidViewed).length * 100
                : 0) / totalRecorded,
            ),
          });
        }

        //progress
        var TrainStart = moment(
          d.progress.general.Training1Start,
          "YYYY-MM-DD",
        );
        var ProjectStart = moment(
          d.progress.general.ProjectStart,
          "YYYY-MM-DD",
        );
        setProgress({
          training: {
            current: Math.round(
              moment.duration(moment().diff(TrainStart)).asDays(),
            ),
            total: Math.round(
              moment
                .duration(
                  moment(d.progress.general.Training1End, "YYYY-MM-DD").diff(
                    TrainStart,
                  ),
                )
                .asDays(),
            ),
          },
          project: {
            current: Math.round(
              moment.duration(moment().diff(ProjectStart)).asDays(),
            ),
            total: Math.round(
              moment
                .duration(
                  moment(d.progress.general.ProjectEnd, "YYYY-MM-DD").diff(
                    ProjectStart,
                  ),
                )
                .asDays(),
            ),
          },
        });
      });
    }
  }, [user, db]);

  const downloadDocument = (url) =>
    db.getStorageItem(url, (file) => window.open(file, "_blank"));

  const openCalendars = () => {
    window.open(
      "https://calendar.google.com/calendar/embed?src=c_0lbq0flg0r0gkcs15068uq44oo%40group.calendar.google.com&ctz=Asia%2FKolkata",
      "_blank",
    );
    window.open(
      "https://calendar.google.com/calendar/embed?src=c_2ei52594ibooqvr0pkdqvv5qvs%40group.calendar.google.com&ctz=Asia%2FKolkata",
      "_blank",
    );
  };

  const getCalendarItemColor = (title, isLive) => {
    if (title.includes("Day")) return isLive ? "green" : "grey";
    else if (title.includes("Crash")) return "grey";
    else if (
      title.includes("Briefing") ||
      title.includes("Keynote") ||
      title.includes("Valedictory")
    )
      return "green";
    else if (title.includes("Task")) return "orange";
    else return "red";
  };

  const refreshUser = () => db.loginFetch(setUser);

  const logout = () =>
    db.logout(() => {
      localStorage.removeItem("terms");
      setRedirect("/login");
    });

  if (redirect) return <Redirect to={{ pathname: redirect, state: user }} />;
  return (
    <Fragment>
      <DashboardNavBar
        user={user || { name: "" }}
        onClick={() => logout()}
        showRefer={progress && progress.training.current >= REFER_POPUP_TIMEOUT}
        showNDA={true}
        acceptNDA={db.acceptNDA}
      />

      <TermsModal />
      {modalSession && user && (
        <VidPlayerModal
          sessionTitle={modalSession}
          showVidModal={showVidModal}
          setShowVidModal={setShowVidModal}
          onStart={() => db.videoClickAnalytics(modalSession.id, modalSession.title, user.name, user.phone)}
        />
      )}

      {showRefer && ((progress && progress.training.current < REFER_POPUP_TIMEOUT) || sessionEnded) && (
        <Alert className="referBox" variant="success" style={{ position: "relative", top: -15, marginBottom: -120 }}>
          <div
            style={{
              fontSize: 20,
              float: "right",
              userSelect: "none",
              cursor: "pointer",
              padding: 5,
              margin: 5,
            }}
            onClick={() => setShowRefer(false)}
          >
            <b className="fas">{"\uf00d"}</b>
          </div>
          <b>Refer & Earn</b>
          <br />
          <span>Refer this program to a friend and get upto 400 Rs when they join AND they get 10% off! It's a win-win.* </span>
          <h3 className="refer" onClick={() => (window.location.href = "old/refer.html")}>
            Refer Now
          </h3>
        </Alert>
      )}

      {mcqModal && mcqModalTitle && (
        <McqModal
          mcqData={mcqData}
          mcqModal={mcqModal}
          setMcqModal={setMcqModal}
          mcqModalTitle={mcqModalTitle}
          saveScore={db.setMcqScore}
          userId={user.phone}
          refreshUser={refreshUser}
        />
      )}

      {showAgendaModal && (
        <AgendaModal
          {...{
            calendarItems,
            getCalendarItemColor,
            showAgendaModal,
            setShowAgendaModal,
          }}
        />
      )}

      {assignmentItem && assignmentsModal && (
        <DragDropModal
          {...{
            userId: user.phone,
            item: assignmentItem,
            assignmentsModal,
            setAssignmentsModal,
            db,
            refreshUser,
          }}
        />
      )}

      {sessionEnded && (
        <div className="ended-container" style={{ marginTop: 120 }}>
          <img src={require("./images/main/party.svg")} style={{ width: "80%", marginBottom: "10%" }} alt="" />
          <h3>It's ...over.</h3>
          <span>
            This session has officially ended! Thank you for being part of this Next Up Curated Program. You can download your documents, fill out any
            feedback forms or follow us on your social media to stay in touch with us.
          </span>
        </div>
      )}

      {!sessionEnded && (
        <Fragment>
          <div className="container" style={{ marginTop: 120 }}>
            {user ? (
              <div className="profile">
                <div
                  style={{
                    display: "inline-block",
                    width: "9%",
                    marginRight: "1%",
                  }}
                >
                  <Circle color="#5e09b8" icon={"\uf007"} />
                </div>

                <div className="profileName">
                  <div style={{ marginBottom: "-2%", fontSize: "20px" }}>{user.name}</div>
                  <div className="smallText" style={{ width: "40%" }}>
                    {user.course.toUpperCase()}
                  </div>
                </div>

                {progress && (
                  <div className="progressProfile">
                    <ProgressBar
                      variant="primary"
                      now={(progress.training.current / progress.training.total) * 100}
                      style={{ borderRadius: "10px" }}
                    ></ProgressBar>
                    <div className="smallText" style={{ marginTop: 6 }}>
                      Training Phase: Day {progress.training.current} of {progress.training.total}
                    </div>
                    <br />
                    {/* <div className='smallText'>Status: In Progress</div> */}
                    {attendanceTotal && (
                      <div className="smallText">
                        Live Attendance: {attendanceTotal.live}% | Recorded Attendance: {attendanceTotal.recorded}%
                      </div>
                    )}
                  </div>
                )}
                <FontAwesomeIcon
                  icon={showArrow ? faAngleUp : faAngleDown}
                  className="arrow pressable"
                  onClick={() => {
                    setShowArrow(!showArrow);
                  }}
                />
              </div>
            ) : (
              <Spinner animation="border" />
            )}

            {showArrow && (
              <div className="projectProgressContainer">
                {progress && (
                  <div className="projectPhase">
                    <ProgressBar
                      variant="primary"
                      now={(progress.project.current / progress.project.total) * 100}
                      style={{ borderRadius: "10px" }}
                    ></ProgressBar>
                    <div className="smallText" style={{ width: "100%", marginTop: 8 }}>
                      Project Phase: {progress.project.current < 0 ? "Not Started" : `Day ${progress.project.current} of ${progress.project.total}`}{" "}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="impUpdate">
              <hr />
              <FontAwesomeIcon icon={faBell} style={{ fontSize: "24px", margin: "0 2% 0 0" }} />
              {notifications ? (
                notifications.map((n, ni) => (
                  <span key={ni}>
                    {n}
                    <br />
                  </span>
                ))
              ) : (
                <Spinner animation="border" />
              )}
            </div>
          </div>

          <h3 className="headings">Coming Up</h3>
          <div className="container">
            <div style={{ display: "inline-flex", width: "95%", flexWrap: "wrap" }}>
              <div className="nextSessions">
                {calendarItems.length > 0 ? (
                  calendarItems
                    .filter((c) => moment(c.start).isSame(calendarSelectedDate, "day"))
                    .map((item, i) => (
                      <Fragment key={i}>
                        <div
                          className="calendarInfo"
                          style={{
                            borderColor: getCalendarItemColor(item.title, item.url && true),
                          }}
                        >
                          <div className="sessionTitle" style={{ fontWeight: 500 }}>
                            {item.title}
                          </div>
                          {item.url && (
                            <div className="gMeetButton pressable" alt="" onClick={() => window.open(item.url, "_blank").focus()}>
                              <img src={require("./images/main/meet.png")} className="gMeetLogo" alt="" />
                            </div>
                          )}

                          <div className="smallText" style={{ width: "40%" }}>
                            {moment(item.start).format("MMM D, h:mm A")} - {moment(item.end).format("h:mm A")}
                          </div>
                          <br />
                        </div>{" "}
                        <br />
                      </Fragment>
                    ))
                ) : (
                  <Spinner animation="border" style={{ marginTop: "15%", marginBottom: "15%" }} />
                )}
              </div>
              <div className="calendarBox">
                <Calendar onChange={setSelectedDate} value={calendarSelectedDate} className="shadow" />
              </div>
            </div>
            <div className="pressable gCalButton" onClick={() => setShowAgendaModal(!showAgendaModal)}>
              Agenda View
            </div>
            <div className="pressable gCalButton" onClick={() => openCalendars()}>
              <img src={require("./images/main/cal.png")} className="gMeetLogo" style={{ float: "left", marginTop: -5 }} alt="" />
              &nbsp;&nbsp;&nbsp;Open in Google Calendar&nbsp;&nbsp;&nbsp;
              <b className="fas">{"\uf35d"}</b>
            </div>
          </div>

          <h3 className="headings">Sessions</h3>
          <div className="container">
            <div className="sessionsContainer">
              {sessions.length > 0 ? (
                sessions
                  .filter((s) => moment(s.date + " 8:30", "YYYY-MM-DD h:mm").isSameOrBefore(moment()))
                  .sort((a, b) => moment(b.date, "YYYY-MM-DD").diff(moment(a.date, "YYYY-MM-DD")))
                  .map((item, i) => (
                    <div
                      key={i}
                      className="vid-container pressable"
                      onClick={() => {
                        setShowVidModal(true);
                        setModalSessions(item);
                      }}
                    >
                      <div className="vid-thumbnail-container">
                        <LazyLoadImage alt={item.title} height={186} src={`old/images/${item.id}.png`} width={330} style={{ objectFit: "cover" }} />
                      </div>
                      <div className="vid-text-container">
                        <span className="vidTitle" style={{ marginBottom: 0 }}>
                          {item.title}
                        </span>
                        <span className="vidDescription" style={{ color: "grey" }}>
                          {moment(item.date, "YYYY-MM-DD").format("MMM D")}
                        </span>
                        <div className="vidDescription">{item.description}</div>
                      </div>
                    </div>
                  ))
              ) : (
                <Spinner animation="border" style={{ marginTop: "12%", marginBottom: "12%" }} />
              )}
            </div>
          </div>

          <h3 className="headings">Evaluation</h3>
          <div className="container">
            <div className="sessionsContainer">
              {user && mcqData ? (
                Object.keys(mcqData).map((item, i) => (
                  <div
                    className="vid-container pressable"
                    key={i}
                    onClick={() => {
                      if (!user.progress.mcq || !user.progress.mcq[item]) {
                        setMcqModal(true);
                        setMcqModalTitle(item);
                      }
                    }}
                    style={{ borderLeft: "8px solid red", padding: 15 }}
                  >
                    {user.progress.mcq && user.progress.mcq[item] && (
                      <span
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          float: "right",
                          marginTop: 10,
                          marginRight: 5,
                        }}
                      >
                        {user.progress.mcq[item]}
                      </span>
                    )}
                    <span className="vidTitle" style={{ marginBottom: 0, marginTop: 8 }}>
                      Audit {i + 1}
                    </span>
                    <span className="vidDescription" style={{ color: "grey" }}>
                      {moment(item, "YYYY-MM-DD").format("MMM D")}
                    </span>
                  </div>
                ))
              ) : (
                <Spinner animation="border" style={{ marginTop: "5%", marginBottom: "5%" }} />
              )}
              <span className="vidDescription" style={{ color: "grey", marginBottom: 0 }}>
                Note: Tests are timed at 1 minute per question with +1/-0 marking. Timer starts when you open one of the tests above.{" "}
              </span>
              <span className="vidDescription" style={{ color: "grey", marginBottom: 0 }}>
                Questions are based on recorded sessions posted above.
              </span>
            </div>
          </div>
        </Fragment>
      )}
      <h3 className="headings">Feedback</h3>
      <div className="container">
        <div className="sessionsContainer">
          {feedback ? (
            Object.values(feedback)
              .filter((s) => moment(s.date + " 16:30", "YYYY-MM-DD h:mm").isSameOrBefore(moment()) && ((sessionEnded && s.required) || !sessionEnded))
              .map((item, i) => (
                <div
                  className="vid-container pressable"
                  key={i}
                  onClick={() => window.open(item.url, "_blank").focus()}
                  style={{ borderLeft: "8px solid #5e09b8", padding: 15 }}
                >
                  {!sessionEnded && item.required && <span style={{ color: "red", fontSize: 32, float: "right" }}>{"\u2022"}</span>}
                  <span className="vidTitle" style={{ marginBottom: 0, marginTop: 8 }}>
                    {item.title}{" "}
                  </span>
                  <span className="vidDescription" style={{ color: "grey" }}>
                    {moment(item.date, "YYYY-MM-DD").format("MMM D")}
                  </span>
                </div>
              ))
          ) : (
            <Spinner animation="border" style={{ marginTop: "5%", marginBottom: "5%" }} />
          )}
          {!sessionEnded && (
            <span className="vidDescription" style={{ color: "grey", marginBottom: 0 }}>
              <span style={{ color: "red", fontSize: 32 }}>{"\u2022"}</span>
              Required
            </span>
          )}
        </div>
      </div>

      <h3 className="headings">Documents</h3>
      <div className="container">
        <div className="sessionsContainer">
          {user && user.documents ? (
            <Fragment>
              {[...user.documents, ...documents]
                .filter((s) => moment(s.date + " 16:30", "YYYY-MM-DD h:mm").isSameOrBefore(moment()))
                .map((item, i) => (
                  <div
                    className="vid-container pressable"
                    key={i}
                    onClick={() => downloadDocument(item.url)}
                    style={{ borderLeft: "8px solid black", padding: 15 }}
                  >
                    <span className="vidTitle" style={{ marginBottom: 0, marginTop: 8 }}>
                      {item.title}
                    </span>
                    <span className="vidDescription" style={{ color: "grey" }}>
                      {moment(item.date, "YYYY-MM-DD").format("MMM D")}
                    </span>
                  </div>
                ))}
            </Fragment>
          ) : (
            <Spinner animation="border" style={{ marginTop: "5%", marginBottom: "5%" }} />
          )}
        </div>
      </div>
      {!sessionEnded && (
        <Fragment>
          <h3 className="headings">Uploads</h3>
          <div className="container">
            <div className="sessionsContainer">
              {generalAssignments ? (
                Object.values(generalAssignments).map((item, i) => (
                  <Fragment key={i}>
                    <div
                      className="vid-container pressable"
                      key={i}
                      onClick={() => {
                        setAssignmentItem(item);
                        setAssignmentsModal(true);
                      }}
                      style={{ borderLeft: "8px solid orange", padding: 15 }}
                    >
                      <span className="vidTitle" style={{ marginBottom: 0, marginTop: 8 }}>
                        {item.title}
                      </span>
                      <span className="vidDescription" style={{ color: "grey" }}>
                        Submit by {moment(item.date, "YYYY-MM-DD").format("MMM D")}
                      </span>
                    </div>
                  </Fragment>
                ))
              ) : (
                <Spinner animation="border" style={{ marginTop: "5%", marginBottom: "5%" }} />
              )}
            </div>
          </div>
        </Fragment>
      )}
      <br />
      <Sitemap />
    </Fragment>
  );
}

export default Dashboard;
