import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import About from "./About";
import AdminConsole from "./AdminConsole";
import "./App.css";
import ArtofPresenting from "./ArtofPresenting";
import Certificate from "./Certificate";
import Contact from "./Contact";
import CreatingAR from "./CreatingAR";
import Dashboard from "./Dashboard";
import DigitalMarketing from "./DigitalMarketing";
import EmployableGraphicDesign from "./EmployableGraphicDesign";
import FundamentalML from "./FundamentalML";
import Gallerys from "./Gallery";
import Login from "./Login";
import Lookup from "./Lookup";
import Main from "./Main";
import ReasonableUI from "./ReasonableUI";
import Reciept from "./Reciept";
import Register from "./Register";
import RenderDocuments from "./RenderDocument";
import UsefulWebDesign from "./UsefulWebDesign";

import DB, { db } from "./DB";

function App() {
  return (
    <DB.Provider value={db}>
      <div className="App">
        <Router>
          <Route exact path="/" component={Main} />
          <Route path="/ar" render={(props) => <CreatingAR {...props} />} />
          <Route path="/ml" render={(props) => <FundamentalML {...props} />} />
          <Route path="/web-design" render={(props) => <UsefulWebDesign {...props} />} />
          <Route path="/ui" render={(props) => <ReasonableUI {...props} />} />
          <Route path="/graphic-design" render={(props) => <EmployableGraphicDesign {...props} />} />
          <Route path="/digital-marketing" render={(props) => <DigitalMarketing {...props} />} />
          <Route path="/presenting" render={(props) => <ArtofPresenting {...props} />} />
          <Route path="/register" render={(props) => <Register {...props} />} />
          <Route path="/contact" render={(props) => <Contact {...props} />} />
          <Route path="/about" render={(props) => <About {...props} />} />
          <Route path="/lookup" render={(props) => <Lookup {...props} />} />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
          <Route path="/admin-console" render={(props) => <AdminConsole {...props} />} />
          <Route path="/gallery" render={(props) => <Gallerys {...props} />} />
          <Route path="/reciept" render={(props) => <Reciept {...props} />} />
          <Route path="/renderdoc" render={(props) => <RenderDocuments {...props} />} />
          <Route path="/certificate" render={(props) => <Certificate {...props} />} />
        </Router>
      </div>
    </DB.Provider>
  );
}

export default App;
