import {
  Document,
  Font,
  G,
  Image,
  Line,
  PDFViewer,
  Page,
  Path,
  Polygon,
  Rect,
  Svg,
  Text,
  Tspan,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import QRCode from "qrcode.react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import DB from "./DB";

Font.register({ family: "OpenSans-Italic", src: `./OpenSans-Italic.ttf` });
Font.register({
  family: "OpenSans-ExtraBold",
  src: `./OpenSans-ExtraBold.ttf`,
});
Font.register({
  family: "OpenSans-BoldItalic",
  src: `./OpenSans-BoldItalic.ttf`,
});
Font.register({ family: "OpenSans-Bold", src: `./OpenSans-Bold.ttf` });
Font.register({ family: "OpenSans-SemiBold", src: `./OpenSans-SemiBold.ttf` });
Font.register({ family: "OpenSans-Regular", src: `./OpenSans-Regular.ttf` });

const CEOSign = () => (
  <G id="Sign_2_">
    <Path
      d="M123.9,465.3C124,465.3,124,465.3,123.9,465.3c0.5,0,0.5,0.3,0.6,0.5c0.2,0.3,0.4,0.6,0.5,1c0.2,0.3,0.3,0.7,0.5,1
			   c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.1,0.4,0.2,0.5c0.2,0.3,0.2,0.7,0.4,1.1c0.1,0.1,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.2,0.3
			   c0.1,0.1,0,0.2,0,0.3c0.1,0.2,0,0.6,0.3,0.7c0,0,0,0,0,0c-0.2,0.2,0,0.3,0.1,0.4c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.4,0.5,0.7,0.7,1
			   c0.2,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.3-0.1,0.4c-0.1,0.1-0.4,0.2-0.5,0c0,0,0,0-0.1,0c-0.4,0-0.4-0.3-0.6-0.5
			   c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.2-0.4-0.2-0.6c-0.1-0.1-0.1-0.3-0.1-0.5c-0.1-0.4-0.3-0.9-0.4-1.3
			   c0,0,0,0,0,0c-0.3-0.1-0.2-0.3-0.2-0.5c0-0.2-0.1-0.4-0.2-0.6c-0.3-0.5-0.3-1-0.6-1.4c-0.1-0.3-0.2-0.6-0.4-0.9
			   c-0.3-0.6-0.5-1.2-0.9-1.7c-0.2-0.3-0.3-0.6-0.5-0.8c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.3-0.4-0.4-0.6
			   c-0.1-0.2-0.3-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.2-0.2,0-0.3c-0.3,0.1-0.3-0.1-0.4-0.2
			   c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.2-0.3-0.4-0.6-0.5c0,0,0,0,0,0c0-0.3-0.4-0.4-0.5-0.6c0-0.1-0.1-0.1-0.2-0.1
			   c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1-0.1-0.1c-0.4-0.1-0.6-0.4-0.9-0.6c-0.3-0.2-0.6-0.3-0.9-0.5c-0.4-0.2-0.8-0.3-1.2-0.5
			   c0,0,0,0-0.1,0c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4,0-0.8,0-1.2,0c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.7,0.1-0.9,0.2
			   c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.1-0.7,0.3-1,0.4c-0.4,0.2-0.7,0.4-1,0.6c-0.1,0.1-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.2,0,0.3
			   c0.1,0.1,0.2,0.2,0.1,0.4c-0.1,0.1-0.1,0.3,0,0.3c0.2,0.3,0,0.6,0.4,0.9c0,0,0,0.1,0,0.1c-0.1,0.1-0.1-0.1-0.2,0
			   c-0.1,0.1,0,0.1,0.1,0.2c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1-0.1,0.1c-0.3-0.1-0.1,0.1-0.1,0.1c0.1,0.1,0.1,0.3,0.1,0.5
			   c0,0.4,0.3,0.7,0.3,1.1c0,0,0,0,0,0c0.2,0.1,0.1,0.3,0.2,0.4c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0.1,0,0.1,0.1
			   c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.1-0.1-0.2,0c0,0.1,0.1,0.1,0.2,0.2c-0.2,0-0.3,0.1-0.1,0.2
			   c0,0,0,0.1,0,0.2c0,0.1-0.1,0.2,0.1,0.2c0,0,0,0,0,0.1c-0.1,0.2,0,0.3,0.1,0.5c0.1,0.3,0,0.6,0.3,0.8c0,0,0,0,0,0.1
			   c-0.3,0.2,0.1,0.2,0.1,0.4c0,0.1,0.1,0.1,0,0.1c-0.1,0,0,0.1,0,0.2c0.2,0.4,0.4,0.7,0.4,1.2c0,0.2,0,0.4,0.1,0.6
			   c0,0.1,0.3,0.1,0.2,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.2,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.1,0.4,0.1
			   c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.1,0.6,0.1,0.8,0.3c0,0,0.1,0,0.1,0c0.3-0.1,0.6,0.1,0.8,0.2c0.3,0.1,0.6,0.3,0.9,0.3
			   c0.4,0.1,0.9,0.3,1.3,0.4c0.2,0-0.1,0.1,0,0.2c0.1-0.2,0.3-0.1,0.4,0c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.1
			   c0.1,0,0.1,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0c-0.2,0-0.3,0.1-0.5,0c0,0-0.1,0-0.1,0c0,0.1,0,0.1,0.1,0.1c0.2,0,0.5,0.1,0.7,0
			   c0.1,0,0.1,0,0.1-0.1c0,0-0.1-0.1,0-0.1c0.1,0,0.2,0,0.2,0c0.2,0.1,0.5,0.2,0.7,0.3c0.4,0.2,0.8,0.2,1.1,0.5
			   c0.1,0.1,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.2-0.1,0.2,0,0.4,0.1c0.4,0.1,0.8,0.3,1.1,0.6
			   c0.1,0.1,0.1-0.1,0.3,0c0.2,0.2,0.5,0.3,0.8,0.4c0.4,0.2,0.8,0.4,1.3,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.2,0.7,0.3
			   c0.4,0.2,0.8,0.5,1.2,0.7c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.1,0.1c0,0,0,0.1,0,0.1c0.4,0.1,0.5,0.4,0.8,0.5
			   c0.3,0.1,0.5,0.3,0.8,0.5c0.3,0.1,0.5,0.4,0.7,0.5c0.3,0.2,0.5,0.4,0.7,0.6c0.1,0.1,0.2,0.1,0.3,0c0.6-0.3,1.3-0.6,1.9-0.9
			   c0.6-0.3,1.3-0.5,1.9-0.8c0.3-0.2,0.7-0.4,1-0.5c0.7-0.2,1.4-0.6,2.1-0.8c0.2,0,0.3-0.2,0.4-0.2c0.5-0.2,1-0.4,1.5-0.6
			   c0.4-0.1,0.7-0.3,1-0.4c0.4-0.2,0.8-0.2,1.1-0.4c0.3-0.1,0.6-0.2,0.8-0.4c0,0,0.1,0,0.2,0c0.4,0,0.6-0.3,0.4-0.6
			   c-0.1-0.3-0.1-0.6,0.1-0.9c0.1-0.1,0-0.2,0.1-0.2c0.2-0.3,0.4-0.7,0.9-0.9c0.5-0.2,1-0.4,1.4-0.7c0.1-0.1,0.2-0.1,0.3-0.1
			   c0,0.1,0,0.2-0.1,0.2c-0.4,0.4-0.7,0.8-1.1,1.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2-0.3,0.3-0.3,0.5c0,0,0,0.1,0,0.1
			   c0.3,0.2-0.1,0.4,0.1,0.6c0.7-0.3,1.5-0.5,2.2-0.8c0.4-0.2,0.9-0.3,1.3-0.5c0.3-0.1,0.7-0.2,1-0.3c0.5-0.2,1-0.4,1.4-0.6
			   c0.6-0.2,1.2-0.4,1.7-0.6c0.5-0.2,0.9-0.3,1.4-0.5c1-0.4,2.1-0.7,3.2-1c0.9-0.3,1.7-0.6,2.6-0.8c0.5-0.2,1-0.4,1.5-0.5
			   c0.7-0.2,1.3-0.4,2-0.6c1.2-0.4,2.3-0.7,3.5-1.1c0.9-0.3,1.9-0.7,2.8-1c0.6-0.2,1.1-0.5,1.7-0.7c0.2-0.1,0.4-0.2,0.6-0.3
			   c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.9,0-1.9,0-2.8,0.1c-0.7,0.1-1.4,0.1-2.1,0.3c-0.5,0.1-1,0.2-1.6,0.2
			   c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.3,0.1-0.4,0c-0.1-0.1-0.1,0-0.1,0c-0.2,0.2-0.4,0.2-0.7,0.1c0-0.1,0.1-0.1,0.2-0.1
			   c0.5,0,1.1-0.2,1.5-0.3c0.6-0.3,1.3-0.2,1.9-0.4c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.5-0.1,0.7-0.1c0.2-0.1,0.5,0,0.8-0.1
			   c0.6-0.1,1.2-0.1,1.8-0.2c0.7-0.1,1.4-0.1,2.1-0.1c0.3,0,0.4,0.1,0.6,0.2c0.1,0.1,0.1,0.4-0.1,0.5c-0.6,0.2-1.1,0.5-1.7,0.8
			   c-0.3,0.2-0.7,0.3-1.1,0.4c-0.7,0.3-1.3,0.5-2,0.8c-0.3,0.1-0.6,0.3-0.9,0.3c0,0-0.1,0-0.1,0c-0.3,0.2-0.8,0.3-1.2,0.4
			   c-0.6,0.3-1.3,0.4-1.9,0.6c-0.4,0.2-0.9,0.3-1.4,0.4c-0.6,0.3-1.3,0.3-1.9,0.6c-0.4,0.2-0.9,0.2-1.3,0.5c-0.2,0.1-0.4,0-0.6,0.1
			   c-0.4,0.2-0.9,0.3-1.3,0.4c-0.5,0.1-0.9,0.3-1.4,0.4c-0.2,0-0.3,0.1-0.4,0.2c-0.5,0.2-1.1,0.4-1.7,0.5c-0.4,0.1-0.8,0.3-1.2,0.4
			   c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.1-0.4,0.1-0.6,0.2c-0.6,0.3-1.2,0.4-1.8,0.6c-0.4,0.2-0.9,0.3-1.3,0.5c-0.2,0.1-0.5,0.2-0.8,0.2
			   c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.5,0.1-0.9,0.3-1.4,0.5c-0.3,0.1-0.6,0.2-1,0.4c-0.3,0.1-0.5,0.2-0.8,0.3
			   c-0.2,0.1-0.2,0.2-0.1,0.3c0.1,0.3,0,0.5,0.1,0.8c0,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.4,0.2,0.7,0.3,1.1
			   c0.1,0.5,0.4,0.9,0.4,1.4c0.1,0.3,0.2,0.6,0.3,1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.2,0.1,0.5,0.3,0.7c0.1,0.2,0.1,0.4,0.2,0.6
			   c0.1,0.4,0.2,0.8,0.4,1.2c0.1,0.2,0.2,0.5,0.3,0.8c0.2,0.5,0.3,0.9,0.5,1.4c0.2,0.7,0.5,1.4,0.7,2.2c0.1,0.3,0.2,0.6,0.3,0.9
			   c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.2,0.1,0.4c0,0.4,0.2,0.7,0.3,1.1c0.1,0.6,0.2,1.2,0.4,1.8
			   c0.1,0.5,0.1,0.9,0.3,1.4c0.1,0.3,0.1,0.7,0.1,1.1c0,0.3,0.1,0.5,0.1,0.8c0,0.5,0.1,0.9,0.1,1.4c0.1,0.6,0,1.3,0.1,1.9
			   c0,0.2,0,0.4,0,0.6c0,0.4,0,0.8,0.1,1.2c0,0.8,0,1.5,0,2.3c0,0.6,0,1.2,0,1.7c0,0.2,0,0.5,0,0.7c0.1,0.3-0.1,0.6-0.5,0.7
			   c-0.2,0-0.3,0.2-0.5,0.1c-0.3,0-0.6-0.1-0.6-0.4c-0.1-0.2-0.1-0.4-0.2-0.7c-0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0.3-0.7,0.3-1
			   c0,0,0-0.1,0.1-0.1c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.2,0.2-0.2c0.1,0,0.2-0.1,0.2-0.2c0.1-0.4,0-0.8,0-1.1
			   c0-0.2,0.1-0.5-0.1-0.8c0-0.1,0-0.3,0.1-0.4c0-0.5,0-0.9,0-1.4c0-0.4-0.1-0.9-0.1-1.3c0-0.3-0.1-0.5-0.1-0.8c0-0.2,0-0.4,0-0.6
			   c-0.1-0.5-0.2-0.9-0.2-1.4c-0.1-0.5-0.2-1-0.2-1.5c0-0.3-0.2-0.7-0.2-1c-0.1-0.6-0.2-1.1-0.4-1.6c-0.1-0.5-0.2-1-0.3-1.5
			   c-0.2-0.5-0.2-0.9-0.4-1.4c-0.1-0.3,0-0.6-0.3-0.9c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.2-0.3-0.2-0.7-0.4-1
			   c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.6-0.4-1.1-0.6-1.7c-0.1-0.4-0.1-0.8-0.3-1.2c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.3-1.1
			   c-0.1-0.3-0.2-0.6-0.4-1c0,0,0-0.1,0-0.1c0-0.5-0.3-1-0.5-1.5c-0.1-0.4-0.2-0.8-0.4-1.3c-0.2-0.5-0.3-0.9-0.4-1.4
			   c-0.2-0.8-0.6-1.5-0.7-2.3c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.2-0.2-0.3-0.5-0.1c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0-0.4,0.1-0.5,0.2
			   c-0.2,0.3-0.6,0.2-0.9,0.3c0,0-0.1,0-0.1,0c-0.2,0.3-0.5,0.3-0.8,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.1-0.6,0.2-0.8,0.3
			   c-0.2,0.1-0.4,0.1-0.6,0.3c-0.1,0.1-0.2,0-0.2,0c-0.2,0.2-0.6,0.1-0.8,0.3c-0.1,0.1-0.4,0.1-0.5,0.2c-0.2,0.3-0.6,0.3-0.9,0.4
			   c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0.1-0.4,0.1-0.5,0.3c0,0.1-0.1,0-0.2,0c-0.2,0-0.4,0.2-0.6,0.1c-0.1,0.2-0.3,0.3-0.4,0.4
			   c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.1-0.7,0.3-1.1,0.5c-0.1,0-0.1,0.1-0.1,0.1c0.1,0.1,0.1,0.3,0.3,0.3c0,0,0.1,0,0.1,0.1
			   c0.1,0.2,0.4,0.4,0.5,0.6c0,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.3,0.1,0.2,0.4,0.4,0.6
			   c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.2,0.5,0.4,0.8c0.2,0.3,0.2,0.7,0.4,1c0.2,0.4,0.1,0.8,0.2,1.1c0.1,0.4,0,0.8,0,1.2
			   c0,0.2,0,0.5-0.1,0.7c-0.1,0.1,0,0.3-0.1,0.4c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2,0.4-0.5,0.7-0.8,1.1c-0.3,0.4-0.7,0.7-1,1
			   c0,0,0,0,0,0c-0.2,0.2-0.3,0.2-0.6,0.1c0,0,0,0,0,0c0.2,0.2,0.2,0.3-0.1,0.4c-0.1,0-0.1,0-0.1,0.1c-0.4,0.4-1,0.7-1.6,1.1
			   c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.6,0.4-1,0.6c-0.6,0.2-1.1,0.5-1.6,0.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.3,0.1-0.4,0.1
			   c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0-0.5,0.1-0.5,0.2c-0.2,0.2-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0.1-0.7,0.2-1.1,0.3
			   c-0.6,0.1-1.1,0.3-1.7,0.3c-0.5,0-0.9,0.2-1.3,0.2c-0.3,0-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4-0.1
			   c-0.1,0.2-0.3,0.2-0.5,0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.6,0-1.1,0.1-1.7,0c-0.3,0-0.7-0.1-1,0c-0.8,0.1-1.5,0-2.3-0.1
			   c-0.4,0-0.8,0-1.2-0.2c-0.1-0.1-0.1,0-0.2,0c-0.3,0-0.5,0-0.7-0.1c-0.5-0.1-1-0.2-1.5-0.3c-0.4-0.1-0.8-0.3-1.1-0.4
			   c-0.8-0.3-1.4-0.8-1.9-1.4c0,0,0,0,0,0c-0.4-0.2-0.4-0.5-0.6-0.8c0,0,0-0.1-0.1,0c-0.1,0.1-0.3,0.1-0.5,0.2
			   c-0.2,0.3-0.6,0.4-0.9,0.6c-0.1,0-0.2,0.1-0.1,0.1c0.2,0.3,0.1,0.7,0.4,0.9c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.2-0.2,0.2
			   c-0.2,0-0.3,0-0.3-0.2c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.3-0.4-0.3c0.2-0.1,0.2-0.2,0.1-0.3
			   c-0.3-0.3-0.3-0.7-0.3-1c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.6-0.4-1.3-0.7-1.9c-0.1-0.3-0.1-0.6-0.2-0.8c-0.3-0.5-0.4-1-0.5-1.5
			   c-0.1-0.4-0.2-0.9-0.4-1.3c-0.2-0.6-0.4-1.2-0.6-1.7c-0.1-0.4-0.4-0.9-0.4-1.3c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1
			   c0.1-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.5-0.3-0.7
			   c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.1-0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.7-0.3,1,0c0.2,0.3,0.4,0.5,0.4,0.9
			   c-0.1,0.3,0.1,0.6,0.3,0.9c0,0,0.1,0.1,0,0.1c-0.2,0.1,0,0.3,0,0.4c0,0,0.1,0.1,0.1,0.1c-0.1,0.3,0.1,0.5,0.2,0.7c0,0,0,0,0,0.1
			   c-0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0.2,0.3,0.2,0.5c0,0.4,0.3,0.7,0.2,1.1c0.3,0.2,0.1,0.5,0.3,0.7c0.1,0.1,0,0.1,0,0.2
			   c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.3,0.9,0.4,1.3c0.1,0.6,0.4,1.1,0.5,1.6c0.1,0.5,0.2,0.9,0.5,1.4
			   c0.1,0.2,0.2,0.5,0.2,0.7c0,0.1,0.1,0.1,0.2,0c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.1-0.1,0.1-0.2
			   c-0.2-0.5-0.2-1-0.2-1.4c0-0.4,0.1-0.8-0.1-1.2c0,0,0,0,0,0c0.3-0.2-0.1-0.5,0.1-0.7c0.1-0.1,0-0.3,0-0.4c0-0.2,0.1-0.5,0-0.7
			   c0-0.1,0-0.3,0.1-0.4c0-0.2,0-0.4,0.1-0.5c0-0.2,0-0.3,0.1-0.4c0.1-0.3,0.2-0.5,0.1-0.8c0-0.1,0-0.1,0-0.1
			   c0.2-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2,0-0.2c0,0,0-0.1,0-0.1c-0.3,0-0.2-0.3-0.2-0.4c-0.1-0.4-0.2-0.8-0.2-1.2
			   c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1-0.5-0.3-0.9-0.3-1.4c0-0.2-0.2-0.4-0.1-0.6c-0.3-0.4-0.3-0.8-0.3-1.3
			   c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.4-0.1-0.8-0.2-1.2c-0.1-0.3,0-0.6-0.1-0.8c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.4-0.1-0.9-0.2-1.3
			   c-0.1-0.5-0.2-1-0.2-1.5c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.1,0-0.2-0.1-0.4c0-0.5-0.1-1-0.2-1.5c0-0.5-0.1-1-0.1-1.5
			   c0-0.2-0.1-0.5-0.1-0.7c0-0.4,0-0.7-0.1-1.1c0-0.3-0.1-0.5,0-0.8c-0.2,0.1-0.1,0.2-0.1,0.2c0,0.3-0.1,0.7-0.2,1
			   c0,0.2-0.2,0.4,0,0.6c0,0,0,0.1,0,0.1c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.2,0,0.5-0.2,0.7c0,0,0,0.1,0,0.2c0.1,0.2,0.1,0.4,0,0.6
			   c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2,0,0.4,0,0.5c0,0.2-0.2,0.4,0,0.6c0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.7-0.1,1
			   c0,0.3-0.1,0.6-0.2,1c-0.1,0.5-0.1,0.9-0.1,1.4c-0.1,0.7-0.1,1.4-0.1,2.2c0,0.3,0,0.5,0.1,0.8c0.1,0.2,0,0.5,0.1,0.7
			   c0.1,0.2,0.1,0.5,0.2,0.6c0.3,0.2,0.3,0.4,0.3,0.6c0,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.2,0,0.5,0.2,0.7
			   c0.1,0.1,0.1,0.1,0.1,0.2c-0.1,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0.2,0.3,0.4,0.3,0.6
			   c0,0.1,0,0.2-0.2,0.2c-0.2,0-0.4-0.1-0.4-0.3c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0c-0.1-0.3-0.5-0.5-0.5-0.9
			   c0-0.2-0.3-0.3-0.4-0.4c-0.3-0.4-0.8-0.6-0.9-1c-0.1-0.3-0.3-0.6-0.4-1c0-0.2-0.2-0.4-0.3-0.6c-0.2-0.2-0.4-0.5-0.6-0.7
			   c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.1-0.2,0.1-0.3c-0.4,0-0.6-0.2-0.8-0.4c-0.3-0.2-0.4-0.6-0.7-0.7c-0.3-0.1-0.6-0.4-0.9-0.4
			   c-0.2,0-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.3,0.2c0,0.2,0.1,0.3,0.2,0.4c0.1,0.3,0.1,0.6,0,0.9c0,0.1-0.1,0.2-0.2,0.3
			   c-0.1,0.1-0.1,0-0.2,0c-0.3-0.4-0.4-0.9-0.5-1.4c0-0.3-0.1-0.6-0.4-0.8c0,0,0,0,0,0c0-0.2,0.3-0.8,0.5-0.9c0.2-0.1,0.7,0,0.9,0.1
			   c0.2,0.2,0.5,0.2,0.8,0.3c0.3,0.1,0.4,0.3,0.6,0.4c0.1,0,0.1,0.1,0.2,0.1c0.3,0,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.1,0.3,0.2
			   c0.2,0.1,0.3,0.3,0.3,0.4c0.1,0.2,0.3,0.2,0.3,0.4c0.1,0.4,0.6,0.7,0.8,1.1c0,0.1,0.2,0.1,0.3,0.3c0.1-0.2,0.1-0.5,0-0.7
			   c0,0-0.1-0.1,0-0.1c0.2-0.3,0.1-0.5,0.1-0.8c0-0.4,0-0.8,0.1-1.2c0.1-0.3,0-0.5,0.1-0.8c0.1-0.5,0.2-1.1,0.3-1.6
			   c0-0.4,0.1-0.7,0.1-1.1c0-0.4,0.1-0.7,0.2-1.1c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.1,0.1-0.3,0.1-0.5c0-0.3,0-0.6,0.1-0.9
			   c0-0.2,0.1-0.5,0-0.7c-0.1-0.3,0-0.6,0-0.9c0-0.4,0.1-0.8,0.2-1.2c0-0.3,0.1-0.7,0.2-1c0-0.3,0-0.6,0.1-0.9
			   c0.1-0.3,0.1-0.6,0.2-0.9c0-0.4,0.2-0.8,0-1.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.3,0.4-0.3,0.6-0.4c0,0,0.1,0,0.2-0.1
			   c0.4-0.1,0.6-0.1,0.9,0.2c0.2,0.2,0.1,0.4,0.1,0.6c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.1,0.6,0,0.8
			   c0,0.1,0,0.2,0,0.2c-0.1,0.4,0,0.9,0,1.3c0,0.1,0,0.3,0.1,0.5c0,0.4,0,0.7,0,1.1c0,0.3-0.1,0.7,0.1,1c0,0,0,0.1,0,0.1
			   c-0.2,0.3-0.1,0.7,0,1c0.1,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0,0.1
			   c-0.1,0.2-0.1,0.5,0,0.7c0,0,0,0.1,0,0.1c-0.1,0.3,0,0.6,0.1,0.9c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2-0.1,0.3,0.1,0.5
			   c0,0,0.1,0.1,0,0.2c-0.1,0.2,0,0.4,0.1,0.6c0,0.1-0.1,0.2,0,0.3c0,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.1,0.3,0,0.5
			   c-0.1,0.1,0,0.3,0,0.4c0,0.3,0.2,0.5,0.3,0.7c0.1,0.3,0.1,0.5,0.1,0.8c0,0.1-0.1,0-0.1,0.1c0.1,0,0.3,0,0.3,0.2
			   c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0.2,0.3,0.2,0.4
			   c-0.1,0.4,0.2,0.7,0.2,1c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0.2,0.3,0.2,0.7,0.2,1c0,0.1,0.2,0.2,0,0.3c0.4,0.1,0.2,0.4,0.3,0.7
			   c0.1,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0-0.3,0.1-0.5,0.2-0.7c0.2-0.5,0.4-1,0.5-1.5
			   c0.2-0.4,0.4-0.8,0.5-1.3c0-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.5,0.6-0.9,0.9-1.4c0.1-0.2,0.1-0.3,0.3-0.5
			   c0.1-0.1,0.2-0.3,0.4-0.5c0.1-0.1,0-0.2,0.2-0.3c-0.1-0.2,0.3-0.2,0.2-0.4c0-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.3,0.4-0.4
			   c0.1,0,0.1-0.1,0-0.1c0,0,0-0.1,0-0.1c0.3-0.1,0.4-0.4,0.5-0.6c0-0.1,0.2-0.2,0.3-0.2c0.2,0,0.2,0,0.2-0.1c0-0.1,0.1-0.1,0.2-0.2
			   c0.4-0.2,0.6-0.5,1-0.8c0,0,0,0,0.1-0.1c0.1,0,0.3,0,0.3-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.3-0.4-0.2-0.6
			   c0-0.3-0.2-0.5-0.2-0.8c0-0.2-0.2-0.4-0.5-0.5c-0.3-0.1-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.4-0.2-0.9-0.3-1.3-0.5
			   c-0.4-0.2-0.8-0.3-1.2-0.5c-0.5-0.2-0.9-0.4-1.3-0.7c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.4-0.7-0.6-0.8-1c0-0.2-0.2-0.4-0.3-0.6
			   c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.5,0-1,0.2-1.4c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.4-0.7,0.7-1
			   c0.3-0.3,0.6-0.7,0.9-1c0.4-0.4,0.9-0.9,1.4-1.2c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.4-0.3-0.7-0.3-1.1
			   c0-0.4-0.3-0.7-0.4-1c-0.1-0.3-0.2-0.5-0.4-0.7c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.2-0.4c-0.1-0.1-0.1-0.3-0.2-0.4
			   c-0.1-0.1-0.1-0.2-0.3-0.3c-0.3-0.1-0.4-0.6-0.1-0.8c0.2-0.1,0.2-0.2,0.2-0.4c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.1,0,0.2,0
			   c0.6-0.1,0.9,0.1,1,0.6c0.1,0.3,0.1,0.5,0.3,0.8c0,0,0,0.1,0,0.1c-0.1,0.3,0,0.6,0.2,0.9c0,0,0,0.1,0,0.1c0,0.5,0.1,0.9,0.1,1.4
			   c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0.1,0.2c0.1,0,0.2,0,0.2-0.1
			   c0.1-0.1,0.3-0.2,0.5-0.3c0.4-0.2,0.7-0.4,1.1-0.6c0.4-0.2,0.9-0.4,1.4-0.5c0.2,0,0.3-0.1,0.5-0.2c0.3,0,0.5-0.1,0.8-0.1
			   c0.2,0,0.5-0.1,0.7-0.1c0.4,0,0.8,0,1.1,0.1c0.4,0,0.8,0.2,1.3,0.3c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.3,0.5,0.2c0,0,0.1,0,0.1,0
			   c0.4,0.3,0.8,0.5,1.2,0.7c0.4,0.3,0.8,0.6,1.2,0.8c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.5,0.9,0.8c0.2,0.2,0.3,0.3,0.5,0.5
			   c0.2,0.2,0.4,0.4,0.6,0.7c0.3,0.4,0.6,0.7,0.8,1.1c0.3,0.4,0.5,0.9,0.9,1.2c0.1,0.1,0.2,0.3,0.2,0.4
			   C124.2,465.2,124.2,465.3,123.9,465.3z M119.9,485.8c0,0-0.1,0.1-0.1,0.1c-0.2,0-0.2,0.1-0.1,0.3c0.1,0,0.2-0.1,0.3-0.1
			   C120.1,485.9,120.1,485.8,119.9,485.8c0.3-0.2,0.7-0.3,1-0.5c0.2-0.1,0.4-0.2,0.5-0.2c0.3-0.1,0.6-0.5,1.1-0.3c0.1,0,0.1,0,0.1,0
			   c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0.2-0.2,0.5-0.1,0.6-0.4c0,0,0,0,0,0c0.3-0.1,0.6-0.3,1-0.3c0,0,0,0,0.1-0.1
			   c0.1-0.2,0.3-0.2,0.5-0.3c0.4-0.2,0.8-0.3,1.2-0.6c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0.1,0,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.3
			   c0.1,0,0.2,0.1,0.2,0c0.1-0.3,0.4-0.3,0.6-0.4c0.4-0.2,0.7-0.3,1.1-0.5c0.2-0.1,0.4-0.3,0.7-0.3c0.1,0,0.2-0.1,0.3-0.1
			   c0.3-0.1,0.5-0.3,0.8-0.4c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.2,0-0.3-0.1c-0.1-0.2-0.4-0.3-0.5-0.5c-0.1-0.1-0.2-0.3-0.3-0.3
			   c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.5-0.4-0.8-0.5c-0.3-0.2-0.6-0.4-0.9-0.6c-0.4-0.2-0.8-0.5-1.3-0.7
			   c-0.3-0.2-0.7-0.2-0.8-0.5c0,0,0,0-0.1,0c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.3,0-0.4-0.2c-0.3,0-0.5-0.4-0.9-0.3c0,0-0.1,0-0.1,0
			   c-0.2-0.2-0.4-0.2-0.6-0.3c-0.4-0.2-0.8-0.5-1.4-0.5c-0.3,0-0.5-0.2-0.7-0.3c-0.1,0-0.1-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.2
			   c-0.2,0.2-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5-0.1-0.6-0.3c0-0.1-0.1,0-0.1,0c-0.4,0.1-0.7-0.1-1-0.3
			   c-0.2-0.1-0.3-0.3-0.6-0.3c-0.2,0-0.3-0.2-0.5-0.2c-0.3-0.1-0.7-0.1-0.9-0.2c-0.4-0.2-0.9-0.2-1.4-0.4c-0.3-0.1-0.6-0.2-0.9-0.3
			   c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.5-0.3-0.7-0.3c-0.2,0-0.4-0.1-0.6-0.1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.2,0.5,0.3,0.8
			   c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.5,0.3,0.8c0.1,0.1,0.1,0.2,0.1,0.2c0,0.3,0.2,0.6,0.3,1c0.1,0.1,0.1,0.3,0.2,0.5
			   c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0,0.5,0.3,0.6c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.2,0.7
			   c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.3-0.4,0.6-0.5c0.1,0,0.1-0.2,0.2-0.2c0.1,0,0,0.2,0,0.3
			   c0.1,0.3-0.2,0.6-0.2,0.9c-0.1,0.3-0.4,0.5-0.3,0.8c0,0.1,0,0.1-0.1,0.1c-0.2,0.1-0.3,0.2-0.3,0.3c0,0.1,0,0.2-0.1,0.2
			   c-0.2,0.1-0.2,0.2-0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.5c0.4,0.5,0.5,1.2,0.8,1.7c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.1,0.4
			   c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.4,0.3,0.7,0.4,1
			   c0,0.1,0.1,0.3,0.3,0.2c0.4-0.2,0.8-0.4,1.1-0.5C119.5,486,119.7,485.8,119.9,485.8z M110.5,491c0.1,0.2,0,0.3-0.2,0.4
			   c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.1-0.6,0.4-0.6,0.8c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.7,0.3-1-0.1c-0.1-0.2-0.2-0.3-0.4-0.5
			   c-0.1-0.1-0.2-0.1-0.2,0c-0.2,0.2-0.6,0.2-0.8,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1,0,0.2
			   c0.2,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.8,0.5,1.2,0.7c0.3,0.2,0.8,0.2,1.1,0.4c0,0,0.1,0,0.1,0
			   c0.5,0.2,1,0.2,1.6,0.3c0.7,0.2,1.4,0.2,2.1,0.2c0.2,0,0.5,0,0.7,0c0.5,0.1,0.9,0.1,1.4,0.1c0.7,0,1.4,0,2.1-0.1
			   c0.4,0,0.7,0.1,1.1-0.1c0.1,0,0.2,0,0.1,0.1c0.1-0.1,0.1-0.1,0.3-0.1c0.6-0.1,1.2-0.1,1.8-0.2c0.6-0.1,1.2-0.1,1.7-0.3
			   c0,0,0.1,0,0.2,0c0,0.1-0.2,0.1,0,0.2c0.1,0,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.3-0.1,0.5-0.1
			   c0.4-0.1,0.8-0.2,1.2-0.3c0.3-0.1,0.7-0.2,0.9-0.3c0.3-0.2,0.7-0.2,1-0.4c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.3-0.1
			   c0-0.1,0.1-0.2,0.3-0.2c0.3-0.1,0.6-0.2,0.8-0.3c0.5-0.2,1-0.5,1.5-0.8c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0.1-0.2,0.2,0,0.2
			   c0,0,0.1,0,0.1-0.1c-0.1-0.3,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.1,0.3,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c-0.1-0.1,0-0.2,0.1-0.2
			   c0.2-0.1,0.5-0.3,0.6-0.5c0,0,0,0,0,0c0.4-0.1,0.6-0.4,0.8-0.6c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0.3-0.1,0.1-0.2c-0.1,0,0-0.1,0-0.1
			   c0.1,0,0.1,0,0.2-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0
			   c0.1-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0.2-0.2,0.3-0.5,0.5-0.7c0.2-0.4,0.3-0.8,0.4-1.1c0.2-0.4,0-0.9,0-1.3
			   c0-0.4-0.1-0.8-0.2-1.2c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.3-0.6-0.5-1c-0.3-0.5-0.8-1-1.1-1.5
			   c-0.3-0.5-0.8-0.8-1.2-1.2c-0.1-0.1-0.1-0.1-0.2,0c-0.2,0.2-0.5,0.2-0.7,0.4c0,0-0.1,0.1-0.2,0c0,0,0-0.1,0.1-0.1
			   c0.1,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0.1,0.2-0.2,0.2-0.3,0.3c-0.1,0.2-0.4,0.2-0.6,0.3
			   c-0.2,0.1-0.5,0.2-0.7,0.4c0,0.1-0.1,0-0.2,0c-0.2,0.1-0.5,0-0.7,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1
			   c-0.2,0.1-0.3,0.2-0.6,0.3c-0.1,0-0.4,0-0.4,0.3c-0.1,0-0.2-0.1-0.3,0c0,0.1,0.2,0.1,0.1,0.1c-0.1,0-0.2,0.1-0.3,0
			   c-0.1-0.1-0.1-0.1-0.2,0c0.1,0.1,0.3,0,0.2,0.1c-0.2,0.2-0.5,0.3-0.8,0.4c-0.2,0.1-0.1-0.2-0.3-0.1c-0.2,0.1,0.1,0.2,0,0.2
			   c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.1,0-0.1,0c0,0.3-0.2,0.1-0.4,0.2c0.2,0.1,0.1,0.2,0,0.2c-0.1,0.1-0.2,0.1-0.3,0.1
			   c-0.3,0.1-0.6,0.3-1,0.4c-0.3,0.1-0.6,0.3-0.9,0.4c-0.4,0.2-0.8,0.4-1.2,0.6c-0.3,0.1-0.5,0.3-0.8,0.4c-0.2,0-0.3,0.1-0.4,0.3
			   c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.2,0.3,0.5,0.3,0.7c0,0.1-0.1,0.3-0.2,0.3
			   c-0.2,0-0.4,0-0.6-0.2c0,0-0.1,0-0.1-0.1c0.1-0.2-0.2-0.2-0.2-0.4c0,0,0-0.1-0.1,0c-0.3,0.1-0.5,0.2-0.8,0.3
			   c-0.3,0.1-0.5,0.4-0.8,0.4c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.4,0.1-0.5,0.4c0,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.2
			   c0,0,0,0.1-0.1,0.1c-0.2,0.1-0.5,0.2-0.6,0.3c-0.3,0.2-0.6,0.4-0.9,0.5c-0.5,0.1-0.8,0.4-1.2,0.6c-0.1,0.1-0.3,0.1-0.5,0.2
			   c-0.1,0-0.2,0.1-0.3,0.1C110.9,491,110.8,491.2,110.5,491z M107.2,490.1c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.2,0.5-0.6,0.8-0.8
			   c0,0,0,0,0-0.1c0-0.1,0-0.1,0.1-0.2c0.3-0.2,0.4-0.4,0.2-0.7c0,0,0-0.1,0-0.1c0.1-0.2,0-0.4,0-0.5c-0.1-0.4,0-0.9-0.1-1.3
			   c-0.1-0.4,0-0.9-0.2-1.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.1-0.4-0.4-0.5c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
			   c0-0.3,0.1-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0.2,0.6,0.2c0,0,0,0,0,0c-0.1,0.1,0.1,0.2,0.2,0.3
			   c0.2,0.2,0.3,0.5,0.3,0.8c0,0.4,0.4,0.6,0.5,0.8c0,0.1,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.2,0,0.3-0.1
			   c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3-0.1-0.4c-0.1,0,0-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.5
			   c-0.1-0.3-0.1-0.6,0-0.8c0-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.9-0.1,0.9,0.2c0,0.3,0.3,0.5,0.2,0.7c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0
			   c0-0.2,0.3-0.3,0.3-0.5c0-0.3,0.3-0.5,0.5-0.8c0,0,0.1-0.1,0.2-0.1c0.3,0,0.4-0.2,0.6-0.3c0.2-0.3,0.4-0.6,0.5-0.9
			   c0-0.1,0.2-0.1,0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0-0.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.3-0.2-0.6-0.2-0.9
			   c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.2-0.2-0.2-0.3c-0.1-0.4-0.1-0.8-0.5-1c-0.1,0,0-0.1,0-0.1
			   c0,0,0.1-0.1,0-0.1c-0.2-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.4-0.2-0.7c0,0,0-0.1,0-0.1c-0.1,0-0.1,0-0.1,0
			   c-0.2,0.2-0.6,0.3-0.7,0.6c0,0-0.1,0.1-0.1,0.1c-0.4,0.2-0.7,0.5-1,0.8c-0.1,0.1-0.2,0.1-0.3,0.1c0,0.1,0.2,0.1,0.1,0.2
			   c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.2,0.4-0.4,0.5c0,0-0.1,0-0.1,0.1c0,0.2-0.1,0.3-0.2,0.4
			   c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.1,0.1-0.1,0.1
			   c-0.1,0.3-0.3,0.7-0.5,1c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.1,0.4-0.2,0.5c-0.3,0.1-0.2,0.3-0.2,0.4c0,0,0,0,0,0.1
			   c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0,0.2c-0.3,0.2-0.3,0.5-0.4,0.8c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.2,0.4-0.1,0.7
			   c0,0,0,0,0,0.1c-0.2,0.3-0.3,0.6-0.1,0.9c0,0,0,0.1,0.1,0.1c0.1,0.3,0.1,0.6,0.2,0.9c0.2,0.5,0.3,1,0.5,1.5
			   c0.1,0.3,0.1,0.6,0.3,0.8c0.1,0.1,0.2,0.2,0.2,0.4C106.9,489.5,107.1,489.8,107.2,490.1z M115.8,483.8c0.1-0.3-0.2-0.5-0.1-0.8
			   c0-0.1,0-0.3-0.2-0.4c-0.1,0-0.1-0.1-0.1-0.1c0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.4-0.1-0.6c0,0,0-0.1-0.1-0.1
			   c-0.1,0-0.1,0-0.1,0.1c-0.1,0.3-0.4,0.5-0.5,0.7c-0.1,0.3-0.3,0.5-0.5,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1,0,0.2-0.2,0.3
			   c0,0-0.1,0-0.1,0.1c0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.3-0.2,0.5-0.5,0.6c-0.2,0.2-0.5,0.4-0.6,0.7
			   c0,0,0,0.1-0.1,0.1c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.3,0.6-0.6,0.7c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.1
			   c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.4-0.5,0.6-0.8,1c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0.4,0,0.8,0.1,1.1c0,0.3,0,0.5,0,0.8
			   c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.5-0.3,1-0.5,1.5-0.8c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.2,0.7-0.4,1.1-0.6c0.1,0,0.2-0.1,0.3-0.1
			   c0.1-0.1,0.2-0.1,0.2,0c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1c-0.1-0.1,0-0.1,0.1-0.2c0.1,0,0.1,0,0.2-0.1
			   c0.5-0.2,1-0.5,1.5-0.7c0.1,0,0.1-0.1,0.3-0.2c0.6-0.2,1.1-0.5,1.6-0.7c0.1,0,0.2-0.1,0.1-0.2c-0.2-0.1-0.1-0.3-0.1-0.4
			   c-0.2-0.3-0.2-0.7-0.5-0.9c0,0,0-0.1,0-0.1c0.1-0.1,0-0.2-0.1-0.2c-0.2-0.2-0.2-0.4-0.2-0.5c0-0.1,0-0.2-0.1-0.3
			   c-0.2-0.2-0.5-0.4-0.3-0.7c0,0,0-0.1-0.1-0.1C115.9,483.7,115.9,483.8,115.8,483.8z M110.7,468.3c0.1-0.1-0.1-0.2-0.1-0.4
			   c-0.2-0.4-0.2-0.8-0.3-1.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3,0-0.6-0.2-0.9c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.6-0.3-1.1-0.4-1.7
			   c-0.1-0.5-0.3-1-0.4-1.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.2,0.2c0,0.1,0,0.1-0.1,0.2
			   c-0.3,0.2-0.5,0.5-0.8,0.7c-0.1,0.1-0.1,0.3-0.2,0.3c-0.3,0-0.2,0.1-0.3,0.2c0,0.1,0,0.1-0.1,0.2c-0.4,0.4-0.5,0.9-0.8,1.3
			   c-0.2,0.4-0.4,0.8-0.5,1.1c-0.3,0.6-0.2,1.2-0.1,1.8c0.1,0.7,0.7,1.2,1.2,1.7c0.5,0.4,1.2,0.8,1.8,1.1c0.2,0.1,0.4,0.2,0.7,0.3
			   c0.3,0,0.6,0.2,0.9,0.3c0.4,0.1,0.6,0.4,1,0.4c0.2,0,0.2,0,0.2-0.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.2-0.5-0.2-0.8
			   C111,469,110.9,468.6,110.7,468.3C110.7,468.3,110.7,468.3,110.7,468.3z M106.8,490.6c0-0.1-0.1-0.1-0.1-0.2
			   c-0.1-0.6-0.4-1.1-0.6-1.6c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.4-0.3-0.9-0.4-1.3c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0.2
			   c0,0,0,0.1,0,0.1c0,0.2,0,0.4-0.1,0.5c-0.1,0.2,0.1,0.5-0.1,0.7c0,0,0,0,0,0.1c0.1,0.1,0,0.1,0,0.2c-0.1,0.6,0,1.2,0,1.7
			   c0,0.4,0,0.7,0.1,1.1c0,0.3,0,0.5,0.1,0.8c0,0.1,0.1,0.1,0.2,0c0.1-0.1,0.3-0.2,0.4-0.3C106.1,491.3,106.3,490.9,106.8,490.6
			   C106.7,490.6,106.7,490.6,106.8,490.6z M108.7,489.9c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3
			   c-0.2,0.2-0.3,0.6-0.7,0.7c-0.1,0,0,0.1,0,0.1c0,0.3,0.1,0.5,0.2,0.7c0.1,0.3,0.2,0.3,0.5,0.1c0.1-0.1,0.2-0.1,0.3-0.2
			   c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.5,0-0.7C108.8,490.3,108.7,490.1,108.7,489.9z M107.2,491.5c0-0.1,0-0.2-0.1-0.2
			   c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.6,0.7-1.1,1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1
			   c0.1,0,0.1,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.4-0.1,0.8-0.3,1.2-0.5c0.2-0.1,0.4-0.2,0.3-0.5
			   C107.2,491.8,107.1,491.6,107.2,491.5z M109.4,486.2c0,0.2-0.1,0.4,0,0.6c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.1,0,0.2,0,0.4
			   c0,0.1,0.1,0.1,0.1,0c0.2-0.1,0.3-0.4,0.5-0.6c0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0-0.1C109.6,486.5,109.6,486.3,109.4,486.2z
				M108.4,492.9c0.4-0.1,0.2-0.4,0.4-0.6c0,0,0-0.1-0.1-0.1c-0.1,0-0.5,0.2-0.5,0.3C108.3,492.6,108.4,492.7,108.4,492.9z"
    />
    <Path
      d="M126.3,473.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2C126.1,473.5,126.2,473.4,126.3,473.4z
			   "
    />
    <Path d="M124.5,462.2c-0.3,0-0.4-0.2-0.2-0.4C124.3,462,124.5,462,124.5,462.2z" />
    <Path
      d="M119.3,479.9c-0.2,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.2-0.2-0.1-0.3c0.1-0.1,0.1-0.1-0.1-0.2c0,0,0,0,0-0.1
			   c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.3-0.3-0.6-0.4-1c0,0,0,0,0,0c-0.2-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.3-0.3-0.5-0.3
			   c-0.2,0-0.4-0.4-0.3-0.6c0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.3,0.4-0.5,0.9-0.4c0.2,0,0.4,0.1,0.7,0.1c0.2,0,0.3,0.3,0.2,0.4
			   c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1-0.1,0.2,0,0.4c0,0,0,0.1,0,0.1c0.3,0.2,0.2,0.5,0.3,0.7
			   c0.1,0.4,0.2,0.9,0.8,1.2c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1,0,0.3-0.1,0.4c0,0,0,0,0,0.1C119.9,479.9,119.6,479.9,119.3,479.9z"
    />
  </G>
);

const InternCertificate = (props) => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 841.9 595.3"
    style={{ backgroundColor: "#FFF", position: "absolute", top: 0, left: 0 }}
  >
    <G id="Layer_1">
      <G id="BG" style={{ opacity: 7e-2 }}>
        <G id="Layer_3_2_">
          <Path
            style={{ fill: "#010101" }}
            d="M757.5-92.2c-157.1,0-284.4,127.3-284.4,284.4s127.3,284.4,284.4,284.4s284.4-127.3,284.4-284.4l0,0
			   C1041.9,35.1,914.6-92.2,757.5-92.2z M554.8,297.9c-2.3-6.6-0.9-13.9,3.5-19.3L740.8,39.5c3-4,7.3-6.7,12.2-7.7v144L554.8,297.9z
				M940.1,312.3c0,0-136.9-44.3-182.5-44.3S575,312.3,575,312.3c-5.8,0-11.4-2.3-15.4-6.4l197.9-122.1l197.9,122.1
			   C951.4,310,945.9,312.4,940.1,312.3z M762,175.8v-144c4.9,1,9.2,3.7,12.2,7.7l182.5,239.1c4.5,5.4,5.8,12.7,3.5,19.3L762,175.8z"
          />
        </G>
      </G>
      <Rect
        id="Border"
        x="63.8"
        y="63.8"
        style={{
          fill: "none",
          stroke: " #000000",
          strokeWidth: 0.75,
          strokeMiterlimit: 10,
        }}
        width="714.3"
        height="467.7"
      />
      <Polygon
        id="Header"
        style={{ fill: "#000" }}
        points="32.5,297.6 32.5,34.9 808.4,34.9 808.4,297.6 841.9,297.6 841.9,0 0,0 0,297.6 	"
      />
      <G id="Logo">
        <G id="Layer_2_2_">
          <Path
            style={{ fill: "#010101" }}
            d="M369.2,102.2h7.3l22.4,33.5h0.1v-33.5h5.5v40.7h-7l-22.7-33.4h-0.1v33.4h-5.5V102.2z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M426.6,136.1l-0.3,0.1c-0.5,0.4-1,0.7-1.5,0.9c-1.1,0.5-2.3,0.7-3.4,0.7c-1.2,0-2.3-0.2-3.4-0.7
			   c-2-0.8-3.6-2.5-4.2-4.5c-0.1-0.3-0.3-0.7-0.3-1.1h10.2v-5.2h-10.1c0.1-0.3,0.1-0.5,0.3-0.8c0.3-1,0.9-1.9,1.6-2.7
			   c0.7-0.8,1.6-1.4,2.6-1.8c1.1-0.4,2.2-0.7,3.4-0.6c1.2,0,2.4,0.2,3.4,0.6c0.5,0.3,1,0.5,1.5,0.9l0.3,0.2l2.9-4.3l-0.2-0.2
			   c-0.8-0.5-1.6-0.9-2.4-1.3c-3.5-1.4-7.5-1.4-11,0c-3.3,1.4-6,4-7.4,7.4c-1.4,3.5-1.4,7.3,0,10.8c0.7,1.7,1.7,3.2,3,4.4
			   c1.3,1.2,2.8,2.2,4.5,2.9c3.5,1.4,7.5,1.4,11,0c0.9-0.4,1.7-0.8,2.4-1.3l0.2-0.2L426.6,136.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M442,128.2l-9.3-12.9h6.7l6.6,9.4l6.5-9.4h6.3l-8.9,12.9l10.7,14.7h-6.7l-8-11.2l-8,11.2h-6.4L442,128.2z"
          />
          <Polygon
            style={{ fill: "#010101" }}
            points="483.4,115.3 461.6,115.3 461.6,120.5 469.9,120.5 469.9,142.8 475.1,142.8 475.1,120.5 483.4,120.5 
						   "
          />
          <Path
            style={{ fill: "#010101" }}
            d="M503.2,102.2v23.8c0,1.3,0.2,2.7,0.5,4c0.4,1.3,0.9,2.6,1.7,3.7c0.8,1.2,1.9,2.1,3.2,2.8
			   c1.3,0.7,2.9,1.1,4.8,1.1c1.7,0.1,3.3-0.3,4.8-1.1c1.3-0.7,2.3-1.7,3.2-2.8c0.8-1.1,1.4-2.4,1.7-3.7c0.3-1.3,0.5-2.6,0.5-4v-23.8
			   h5.5v24.7c0,2.2-0.4,4.4-1.2,6.5c-0.8,1.9-1.9,3.6-3.3,5c-1.4,1.4-3.1,2.5-5,3.3c-4,1.5-8.4,1.5-12.4,0c-1.9-0.7-3.6-1.8-5-3.3
			   c-1.4-1.4-2.6-3.1-3.3-5c-0.8-2-1.3-4.2-1.2-6.5v-24.7H503.2z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M532.7,142.8v-27.4h9.8c1.3,0,2.7,0.1,4,0.4c1.1,0.2,2.2,0.7,3.2,1.3c0.9,0.6,1.6,1.4,2.1,2.4
			   c0.5,1,0.8,2.3,0.8,3.9c0,1.3-0.2,2.7-0.7,3.9c-0.5,1-1.2,1.8-2,2.4c-0.9,0.6-2,1.1-3.1,1.3c-1.3,0.3-2.6,0.4-4,0.4h-4.9v11.5
			   H532.7z M537.9,127h4.5c0.6,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.5-0.6c0.5-0.3,0.8-0.7,1.1-1.1c0.3-0.6,0.5-1.2,0.4-1.8
			   c0-0.7-0.2-1.4-0.6-2c-0.4-0.5-0.8-0.9-1.4-1.1c-0.6-0.3-1.2-0.4-1.8-0.5l-1.8-0.1h-3.6V127z"
          />
        </G>
        <G id="Layer_3_1_">
          <Path
            style={{ fill: "#010101" }}
            d="M317.7,89.3c-17.6,0-31.8,14.2-31.8,31.8s14.2,31.8,31.8,31.8c17.6,0,31.8-14.2,31.8-31.8
			   S335.3,89.3,317.7,89.3z M295.1,132.9c-0.2-0.7-0.1-1.6,0.4-2.2l20.4-26.7c0.3-0.5,0.8-0.8,1.4-0.9v16.1L295.1,132.9z
				M338.1,134.6c0,0-15.3-5-20.4-5c-5.1,0-20.4,5-20.4,5c-0.6,0-1.2-0.3-1.7-0.7l22.1-13.6l22.1,13.6
			   C339.4,134.3,338.8,134.6,338.1,134.6z M318.2,119.3v-16.1c0.5,0.2,1,0.5,1.4,0.9l20.4,26.7c0.5,0.6,0.6,1.4,0.4,2.2L318.2,119.3
			   z"
          />
        </G>
      </G>
      <Text
        id="Title"
        transform="matrix(1 0 0 1 253.4999 211.7856)"
        style={{
          fontFamily: "OpenSans-ExtraBold",
          fontSize: 26,
          letterSpacing: 2,
        }}
      >
        CERTIFICATE OF INTERNSHIP
      </Text>
      <Text
        transform="matrix(1 0 0 1 345.747 271.0522)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        This is to certify that
      </Text>
      <Text
        transform="matrix(1 0 0 1 180.2007 348.0527)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        has successfully completed the four-week Internship Program on
      </Text>
      <Text
        transform="matrix(1 0 0 1 254.9199 377.0527)"
        style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 17 }}
      >
        {props.user.course === "ML"
          ? "Fundamental Machine Learning "
          : "Creating Augmented Realities "}{" "}
      </Text>
      <Text
        transform="matrix(1 0 0 1 509.3969 377.0527)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        &nbsp;held from
      </Text>
      <Text
        transform="matrix(1 0 0 1 286.2617 406.0527)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        {moment(props.courseDate.start, "YYYY-MM-DD").format("MMMM Do")}
      </Text>
      <Text
        transform="matrix(1 0 0 1 410.707 406.0527)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        to
      </Text>
      <Text
        transform="matrix(1 0 0 1 430.707 406.0527)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        {moment(props.courseDate.end, "YYYY-MM-DD").format("MMMM Do")}
      </Text>
      <Text
        transform="matrix(1 0 0 1 525.0405 406.0527)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 17 }}
      >
        {" "}
        {moment().format("YYYY")}.{" "}
      </Text>
      <G id="CEO_Sign">
        <Text transform="matrix(0.583 0 0 0.583 93.488 506.5145)">
          <Tspan
            x="0"
            y="0"
            style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 18 }}
          >
            CEO &amp; Chairman
          </Tspan>
          <Tspan
            x="35.6"
            y="16.7"
            style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 18 }}
          >
            Next Up
          </Tspan>
        </Text>
        <CEOSign />
      </G>
      <Line
        id="Line"
        style={{ fill: "none", stroke: "#000000", strokeMiterlimit: 10 }}
        x1="198.6"
        y1="322"
        x2="643.2"
        y2="322"
      />
      <G id="NCP">
        <G>
          <Path
            style={{ fill: "#010101" }}
            d="M372.1,474.4l17.8,11c0.2-0.6,0.1-1.3-0.3-1.8l-16.4-21.4c-0.3-0.4-0.7-0.6-1.1-0.7V474.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M371.3,474.4v-12.9c-0.4,0.1-0.8,0.3-1.1,0.7l-16.4,21.4c-0.4,0.5-0.5,1.2-0.3,1.8L371.3,474.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M371.7,475.1l-17.8,10.9c0.4,0.4,0.8,0.6,1.4,0.6c0,0,12.3-4,16.4-4s16.4,4,16.4,4c0.5,0,1-0.2,1.4-0.6
			   L371.7,475.1z"
          />
        </G>
        <G>
          <Path
            style={{ fill: "#010101" }}
            d="M398.9,462.6h2.2l1.7,10.4h-1.6l-0.3-2.1v0H399l-0.3,2h-1.5L398.9,462.6z M400.7,469.5l-0.7-5.1h0l-0.7,5.1
			   H400.7z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M405.9,462.6h2.1l1.6,6.2h0v-6.2h1.4V473h-1.7l-1.9-7.6h0v7.6h-1.4L405.9,462.6L405.9,462.6z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M412.3,462.6h4.4v1.5h-2.8v2.7h2.2v1.5h-2.2v3.2h2.8v1.5h-4.4V462.6z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M419.1,467.7l-1.8-5.1h1.7l1.1,3.3h0l1.1-3.3h1.5l-1.8,5.1l1.9,5.3h-1.7l-1.2-3.6h0l-1.2,3.6h-1.5
			   L419.1,467.7z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M425,464.1h-1.7v-1.5h5v1.5h-1.7v8.9h-1.6L425,464.1L425,464.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M431.4,470.5v-7.9h1.6v8c0,0.7,0.3,1,0.8,1s0.8-0.3,0.8-1v-8h1.6v7.9c0,1.7-0.8,2.6-2.4,2.6
			   C432.2,473.1,431.4,472.2,431.4,470.5z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M437.4,462.6h2.4c1.6,0,2.4,0.9,2.4,2.6v1c0,1.7-0.8,2.6-2.4,2.6H439v4.2h-1.6L437.4,462.6L437.4,462.6z
				M439.8,467.3c0.5,0,0.8-0.2,0.8-1v-1.2c0-0.7-0.3-1-0.8-1H439v3.2H439.8z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M397.5,482.4v-5.6c0-1.6,0.8-2.6,2.4-2.6c1.6,0,2.4,0.9,2.4,2.6v1.1h-1.5v-1.2c0-0.7-0.3-1-0.8-1
			   c-0.5,0-0.8,0.3-0.8,1v5.8c0,0.7,0.3,0.9,0.8,0.9c0.5,0,0.8-0.3,0.8-0.9v-1.6h1.5v1.5c0,1.6-0.8,2.6-2.4,2.6
			   C398.3,485,397.5,484,397.5,482.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M403.2,482.4v-7.9h1.6v8c0,0.7,0.3,1,0.8,1c0.5,0,0.8-0.3,0.8-1v-8h1.6v7.9c0,1.7-0.8,2.6-2.4,2.6
			   S403.2,484,403.2,482.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M409.3,474.4h2.4c1.7,0,2.4,0.8,2.4,2.4v0.6c0,1.1-0.3,1.7-1.1,2.1v0c0.8,0.3,1.1,1,1.1,2.2v1.8
			   c0,0.5,0,0.9,0.2,1.3h-1.7c-0.1-0.3-0.2-0.4-0.2-1.3v-1.9c0-1-0.3-1.3-1-1.3h-0.6v4.4h-1.6L409.3,474.4L409.3,474.4z
				M411.5,478.9c0.6,0,1-0.3,1-1.1V477c0-0.7-0.3-1.1-0.8-1.1h-0.8v3H411.5z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M416.5,474.4h2.2l1.7,10.4h-1.6l-0.3-2.1v0h-1.8l-0.3,2h-1.5L416.5,474.4z M418.3,481.4l-0.7-5.1h0l-0.7,5.1
			   H418.3z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M421.8,475.9H420v-1.5h5v1.5h-1.7v8.9h-1.6L421.8,475.9L421.8,475.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M425.9,474.4h4.4v1.5h-2.8v2.7h2.2v1.5h-2.2v3.2h2.8v1.5h-4.4V474.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M431.3,474.4h2.5c1.6,0,2.4,0.9,2.4,2.6v5.2c0,1.7-0.8,2.6-2.4,2.6h-2.5V474.4z M433.7,483.3
			   c0.5,0,0.8-0.3,0.8-1v-5.4c0-0.7-0.3-1-0.8-1h-0.8v7.4L433.7,483.3L433.7,483.3z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M439.6,474.5h2.4c1.6,0,2.4,0.9,2.4,2.6v1c0,1.7-0.8,2.6-2.4,2.6h-0.8v4.2h-1.6V474.5z M442,479.1
			   c0.5,0,0.8-0.2,0.8-1v-1.2c0-0.7-0.3-1-0.8-1h-0.8v3.2L442,479.1L442,479.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M445.3,474.5h2.4c1.7,0,2.4,0.8,2.4,2.4v0.6c0,1.1-0.3,1.7-1.1,2v0c0.8,0.3,1.1,1,1.1,2.2v1.8
			   c0,0.5,0,0.9,0.2,1.3h-1.7c-0.1-0.3-0.2-0.4-0.2-1.3v-1.9c0-0.9-0.3-1.3-1-1.3h-0.6v4.4h-1.6L445.3,474.5L445.3,474.5z
				M447.5,478.9c0.6,0,1-0.3,1-1.1V477c0-0.7-0.3-1.1-0.8-1.1h-0.7v2.9L447.5,478.9L447.5,478.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M451.1,482.4v-5.4c0-1.7,0.9-2.6,2.5-2.6c1.6,0,2.5,0.9,2.5,2.6v5.4c0,1.7-0.9,2.6-2.5,2.6
			   S451.1,484,451.1,482.4z M454.4,482.5v-5.6c0-0.7-0.3-1-0.8-1s-0.8,0.3-0.8,1v5.6c0,0.7,0.3,1,0.8,1S454.4,483.2,454.4,482.5z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M457,482.4v-5.4c0-1.7,0.8-2.6,2.4-2.6c1.6,0,2.4,0.9,2.4,2.6v0.9h-1.5v-1c0-0.7-0.3-1-0.8-1s-0.8,0.3-0.8,1
			   v5.6c0,0.7,0.3,1,0.8,1s0.8-0.3,0.8-1v-2h-0.8V479h2.3v3.4c0,1.7-0.8,2.6-2.4,2.6C457.8,484.9,457,484,457,482.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M462.9,474.5h2.4c1.7,0,2.4,0.8,2.4,2.4v0.6c0,1.1-0.3,1.7-1.1,2v0c0.8,0.3,1.1,1,1.1,2.2v1.8
			   c0,0.5,0,0.9,0.2,1.3h-1.7c-0.1-0.3-0.2-0.4-0.2-1.3v-1.9c0-0.9-0.3-1.3-1-1.3h-0.6v4.4h-1.6L462.9,474.5L462.9,474.5z
				M465.1,478.9c0.6,0,1-0.3,1-1.1V477c0-0.7-0.3-1.1-0.8-1.1h-0.7v2.9L465.1,478.9L465.1,478.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M470.1,474.5h2.2l1.7,10.3h-1.6l-0.3-2.1v0h-1.8l-0.3,2h-1.5L470.1,474.5z M471.8,481.4l-0.7-5.1h0l-0.7,5.1
			   H471.8z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M474.7,474.5h2.3l1,7.4h0l1-7.4h2.3v10.3h-1.5V477h0l-1.2,7.8h-1.4l-1.2-7.8h0v7.8h-1.4V474.5z"
          />
        </G>
      </G>
      <Text
        id="Verify_at"
        transform="matrix(1 0 0 1 690.7036 522.1223)"
        style={{ fill: "#bcbec0", fontFamily: "OpenSans-Regular", fontSize: 6 }}
      >
        verify at nextup.co.in/lookup
      </Text>{" "}
      {/*myriad*/}
    </G>
    <G id="Layer_2">
      <G id="QR_Group">
        <Rect
          id="QR_Container"
          x="723"
          y="73.4"
          style={{ fill: "#ffffff" }}
          width="46"
          height="46"
        />
        {/* <image style="overflow:visible;" width="100" height="100" id="QR" transform="matrix(0.4046 0 0 0.4046 725.8147 76.208)">
				</image> */}
      </G>
      <Text
        id="Name"
        transform="matrix(1 0 0 1 300 312.3379)"
        style={{ fontFamily: "OpenSans-Regular", fontSize: 22 }}
      >
        {props.user.name}
      </Text>{" "}
      myriad
    </G>
  </Svg>
);

const CompletionCertificate = (props) => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 841.9 595.3"
    style={{ backgroundColor: "#FFF", position: "absolute", top: 0, left: 0 }}
  >
    {/* <style type="Text/css">
	.st0{opacity:0.4;}
	.st1{opacity:0.1;}
	.st2{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st3{opacity:0.2,fill:'none',stroke:'#000000',strokeMiterlimit:10}
	.st4{opacity:0.3;fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st5{opacity:7.000000e-02;}
	.st6{fill:'#010101';}
	.st7{font-family:'OpenSans-ExtraBold';}
	.st8{font-size:24px;}
	.st9{letter-spacing:2;}
	.st10{font-family:'OpenSans-Italic';}
	.st11{font-size:20.166px;}
	.st12{font-family:'OpenSans-BoldItalic';}
	.st13{font-size:21.6255px;}
	.st14{fill:#BCBEC0;}
	.st15{font-family:'MyriadPro-Regular';}
	.st16{font-size:6px;}
	.st17{fill:'#FFFFFF';}
	.st18{font-size:22.4682px;}
</style> */}
    <G id="Layer_1">
      <G>
        <Path
          style={{ fill: "#000" }}
          d="M91.6,74.4l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2l-47.1,8.8
			C92.8,78.6,91,76.5,91.6,74.4z"
        />
        <Path
          style={{ fill: "#000" }}
          d="M207.3,27.7l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2l-47.1,8.8
			C208.4,31.9,206.6,29.8,207.3,27.7z"
        />
        <Path
          style={{ fill: "#000" }}
          d="M-40.7,66.6l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7L12,59.6c-0.3,1-1.2,1.8-2.2,2l-47.1,8.8
			C-39.5,70.8-41.3,68.7-40.7,66.6z"
        />
        <Path
          style={{ opacity: 0.4, fill: "#000" }}
          d="M52.8,53l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7L105.4,46c-0.3,1-1.2,1.8-2.2,2
			l-47.1,8.8C53.9,57.2,52.1,55.1,52.8,53z"
        />
        <Path
          style={{ opacity: 0.4, fill: "#000" }}
          d="M-14.1,72.4L-9,56.5c0.3-0.9,1-1.5,1.9-1.6L32,47.6c1.8-0.3,3.3,1.4,2.7,3.1l-5.1,15.9
			c-0.3,0.9-1,1.5-1.9,1.6l-39.1,7.3C-13.1,75.9-14.6,74.2-14.1,72.4z"
        />
        <Path
          d="M56.7,112.4l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2l-47.1,8.8
			C57.9,116.5,56.1,114.5,56.7,112.4z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M112.7,86.5l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2
			L116,90.3C113.9,90.7,112.1,88.6,112.7,86.5z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M11.4,89.6l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7L64,82.6c-0.3,1-1.2,1.8-2.2,2
			l-47.1,8.8C12.5,93.8,10.7,91.7,11.4,89.6z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M135,37.8l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2
			l-47.1,8.8C136.1,42,134.3,39.9,135,37.8z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M-6.6,98.8L-3,87.6c0.2-0.6,0.7-1,1.3-1.2l27.4-5.1c1.3-0.2,2.3,1,1.9,2.2l-3.5,11.2c-0.2,0.6-0.7,1-1.3,1.2
			L-4.6,101C-5.9,101.2-7,100-6.6,98.8z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M68,58.2l4.2-13.1c0.2-0.7,0.8-1.2,1.5-1.4l32.3-6c1.5-0.3,2.7,1.1,2.3,2.6l-4.2,13.1
			c-0.2,0.7-0.8,1.2-1.5,1.4l-32.3,6C68.8,61,67.6,59.6,68,58.2z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M-44.6,57.7l4.2-13.1c0.2-0.7,0.8-1.2,1.5-1.4l32.3-6c1.5-0.3,2.7,1.1,2.3,2.6l-4.2,13.1
			c-0.2,0.7-0.8,1.2-1.5,1.4l-32.3,6C-43.8,60.6-45,59.2-44.6,57.7z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M-65.4,87.4l4.2-13.1c0.2-0.7,0.8-1.2,1.5-1.4l32.3-6c1.5-0.3,2.7,1.1,2.3,2.6l-4.2,13.1
			c-0.2,0.7-0.8,1.2-1.5,1.4l-32.3,6C-64.7,90.3-65.9,88.9-65.4,87.4z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M-32.5,90.3l4.2-13.1c0.2-0.7,0.8-1.2,1.5-1.4l32.3-6c1.5-0.3,2.7,1.1,2.3,2.6L3.5,85.5
			c-0.2,0.7-0.8,1.2-1.5,1.4l-32.3,6C-31.7,93.1-33,91.7-32.5,90.3z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M-72.5,138.5l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2
			l-47.1,8.8C-71.3,142.6-73.2,140.6-72.5,138.5z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M95.6,26l4.6-14.4c0.2-0.8,0.9-1.3,1.7-1.5l35.3-6.6c1.6-0.3,3,1.2,2.5,2.8l-4.6,14.4
			c-0.2,0.8-0.9,1.3-1.7,1.5L98,28.8C96.4,29.1,95.1,27.5,95.6,26z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M99.9,47.8l4.6-14.4c0.2-0.8,0.9-1.3,1.7-1.5l35.3-6.6c1.6-0.3,3,1.2,2.5,2.8l-4.6,14.4
			c-0.2,0.8-0.9,1.3-1.7,1.5l-35.3,6.6C100.8,50.9,99.4,49.4,99.9,47.8z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M35.1,100.4l4.6-14.6c0.2-0.8,0.9-1.4,1.7-1.5l36-6.7c1.7-0.3,3,1.3,2.5,2.9l-4.6,14.6
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36,6.7C36,103.6,34.6,102,35.1,100.4z"
        />
        <Path
          style={{ fill: "none", stroke: "#000000", strokeMiterlimit: 10 }}
          d="M156.3,56.6l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7l-6.1,19.2c-0.3,1-1.2,1.8-2.2,2
			l-47.1,8.8C157.4,60.8,155.6,58.7,156.3,56.6z"
        />
        <Path
          style={{
            opacity: 0.2,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M47.4,98l6.1-19.2c0.3-1,1.2-1.8,2.2-2l47.1-8.8c2.2-0.4,4,1.6,3.3,3.7L100,91c-0.3,1-1.2,1.8-2.2,2
			l-47.1,8.8C48.5,102.1,46.7,100.1,47.4,98z"
        />
        <Path
          style={{
            opacity: 0.2,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M3.3,119.3l4.3-13.4c0.2-0.7,0.8-1.2,1.6-1.4l33-6.1c1.5-0.3,2.8,1.2,2.3,2.6l-4.3,13.4
			c-0.2,0.7-0.8,1.2-1.6,1.4l-33,6.1C4.1,122.2,2.8,120.8,3.3,119.3z"
        />
        <Path
          style={{
            opacity: 0.2,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M-40,119l4.3-13.4c0.2-0.7,0.8-1.2,1.6-1.4l33-6.1c1.5-0.3,2.8,1.2,2.3,2.6l-4.3,13.4
			c-0.2,0.7-0.8,1.2-1.6,1.4l-33,6.1C-39.1,121.9-40.4,120.5-40,119z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M140.8,92.3l4.9-15.5c0.3-0.8,1-1.4,1.8-1.6l38.1-7.1c1.8-0.3,3.2,1.3,2.7,3l-4.9,15.5
			c-0.3,0.8-1,1.4-1.8,1.6l-38.1,7.1C141.7,95.7,140.2,94,140.8,92.3z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M81.4,61l4.9-15.5c0.3-0.8,1-1.4,1.8-1.6l38.1-7.1c1.8-0.3,3.2,1.3,2.7,3L124,55.3c-0.3,0.8-1,1.4-1.8,1.6
			L84.1,64C82.3,64.4,80.9,62.7,81.4,61z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M127.3,27.9l4-12.7c0.2-0.7,0.8-1.2,1.5-1.3L164,8.1c1.4-0.3,2.6,1.1,2.2,2.5l-4,12.7
			c-0.2,0.7-0.8,1.2-1.5,1.3l-31.2,5.8C128,30.7,126.8,29.3,127.3,27.9z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M29.7,38.7l4.9-15.5c0.3-0.8,1-1.4,1.8-1.6l38.1-7.1c1.8-0.3,3.2,1.3,2.7,3L72.3,33c-0.3,0.8-1,1.4-1.8,1.6
			l-38.1,7.1C30.6,42,29.1,40.4,29.7,38.7z"
        />
      </G>
      <G>
        <Path
          style={{ fill: "#000" }}
          d="M723.6,570.5l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7c-0.2,0.8-0.9,1.4-1.7,1.5
			l-36.2,6.7C724.5,573.7,723.1,572.1,723.6,570.5z"
        />
        <Path
          style={{ fill: "#000" }}
          d="M804.5,516.6l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7c-0.2,0.8-0.9,1.4-1.7,1.5
			l-36.2,6.7C805.4,519.8,804,518.2,804.5,516.6z"
        />
        <Path
          style={{ fill: "#000" }}
          d="M827.2,575.7l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7c-0.2,0.8-0.9,1.4-1.7,1.5
			l-36.2,6.7C828.1,578.8,826.7,577.3,827.2,575.7z"
        />
        <Path
          style={{ opacity: 0.4, fill: "#000" }}
          d="M785.3,586.2l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C786.2,589.4,784.8,587.8,785.3,586.2z"
        />
        <Path
          style={{ opacity: 0.4, fill: "#000" }}
          d="M835.4,545.8l3.9-12.3c0.2-0.7,0.8-1.1,1.4-1.3l30.1-5.6c1.4-0.3,2.5,1.1,2.1,2.4l-3.9,12.3
			c-0.2,0.7-0.8,1.1-1.4,1.3l-30.1,5.6C836.2,548.5,835,547.2,835.4,545.8z"
        />
        <Path
          d="M750.4,599.7l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7c-0.2,0.8-0.9,1.4-1.7,1.5
			l-36.2,6.7C751.3,602.9,749.9,601.3,750.4,599.7z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M707.4,579.8l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C708.3,583,706.9,581.4,707.4,579.8z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M807.6,549.1l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C808.4,552.3,807,550.7,807.6,549.1z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M799.9,599.7l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C800.8,602.9,799.4,601.3,799.9,599.7z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M799.2,558.5l2.7-8.6c0.1-0.5,0.5-0.8,1-0.9l21.1-3.9c1-0.2,1.8,0.7,1.5,1.7l-2.7,8.6c-0.1,0.5-0.5,0.8-1,0.9
			l-21.1,3.9C799.7,560.3,798.9,559.4,799.2,558.5z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M756,558l3.2-10.1c0.2-0.5,0.6-0.9,1.2-1l24.8-4.6c1.1-0.2,2.1,0.9,1.7,2l-3.2,10.1c-0.2,0.5-0.6,0.9-1.2,1
			l-24.8,4.6C756.6,560.2,755.7,559.1,756,558z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M786.4,553.6l3.2-10.1c0.2-0.5,0.6-0.9,1.2-1l24.8-4.6c1.1-0.2,2.1,0.9,1.7,2l-3.2,10.1
			c-0.2,0.5-0.6,0.9-1.2,1l-24.8,4.6C787,555.7,786.1,554.7,786.4,553.6z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M659.1,599.6l3.2-10.1c0.2-0.5,0.6-0.9,1.2-1l24.8-4.6c1.1-0.2,2.1,0.9,1.7,2l-3.2,10.1
			c-0.2,0.5-0.6,0.9-1.2,1l-24.8,4.6C659.7,601.8,658.7,600.7,659.1,599.6z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M681.6,596.5l3.2-10.1c0.2-0.5,0.6-0.9,1.2-1l24.8-4.6c1.1-0.2,2.1,0.9,1.7,2l-3.2,10.1
			c-0.2,0.5-0.6,0.9-1.2,1l-24.8,4.6C682.2,598.7,681.3,597.6,681.6,596.5z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M823.9,596.7l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C824.8,599.9,823.4,598.3,823.9,596.7z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M769.4,533l3.5-11.1c0.2-0.6,0.7-1,1.3-1.1l27.2-5.1c1.2-0.2,2.3,0.9,1.9,2.2l-3.5,11.1
			c-0.2,0.6-0.7,1-1.3,1.1l-27.2,5.1C770.1,535.3,769,534.2,769.4,533z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M828.3,523.2l3.5-11.1c0.2-0.6,0.7-1,1.3-1.1l27.2-5.1c1.2-0.2,2.3,0.9,1.9,2.2l-3.5,11.1
			c-0.2,0.6-0.7,1-1.3,1.1l-27.2,5.1C829,525.6,827.9,524.4,828.3,523.2z"
        />
        <Path
          style={{ opacity: 0.1, fill: "#000" }}
          d="M777.8,590.5l3.6-11.3c0.2-0.6,0.7-1,1.3-1.2l27.7-5.1c1.3-0.2,2.3,1,1.9,2.2l-3.6,11.3
			c-0.2,0.6-0.7,1-1.3,1.2l-27.7,5.1C778.4,592.9,777.4,591.7,777.8,590.5z"
        />
        <Path
          style={{ fill: "none", stroke: "#000000", strokeMiterlimit: 10 }}
          d="M705,605.9l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C705.9,609.1,704.5,607.5,705,605.9z"
        />
        <Path
          style={{
            opacity: 0.2,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M757.7,588.6l4.7-14.7c0.2-0.8,0.9-1.4,1.7-1.5l36.2-6.7c1.7-0.3,3.1,1.3,2.5,2.9l-4.7,14.7
			c-0.2,0.8-0.9,1.4-1.7,1.5l-36.2,6.7C758.5,591.8,757.1,590.2,757.7,588.6z"
        />
        <Path
          style={{
            opacity: 0.2,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M805.1,605.1l3.3-10.3c0.2-0.6,0.6-1,1.2-1.1l25.4-4.7c1.2-0.2,2.1,0.9,1.8,2l-3.3,10.3
			c-0.2,0.6-0.6,1-1.2,1.1l-25.4,4.7C805.8,607.3,804.8,606.2,805.1,605.1z"
        />
        <Path
          style={{
            opacity: 0.2,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M823.8,536.6l3.3-10.3c0.2-0.6,0.6-1,1.2-1.1l25.4-4.7c1.2-0.2,2.1,0.9,1.8,2l-3.3,10.3
			c-0.2,0.6-0.6,1-1.2,1.1l-25.4,4.7C824.4,538.8,823.4,537.7,823.8,536.6z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M694.4,584.3l3.8-11.9c0.2-0.6,0.7-1.1,1.4-1.2l29.4-5.5c1.3-0.3,2.5,1,2.1,2.3l-3.8,11.9
			c-0.2,0.6-0.7,1.1-1.4,1.2l-29.4,5.5C695.1,586.8,694,585.6,694.4,584.3z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M831.5,504.2l3.8-11.9c0.2-0.6,0.7-1.1,1.4-1.2l29.4-5.5c1.3-0.3,2.5,1,2.1,2.3l-3.8,11.9
			c-0.2,0.6-0.7,1.1-1.4,1.2l-29.4,5.5C832.2,506.7,831.1,505.5,831.5,504.2z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M847.8,526.2l3.1-9.8c0.2-0.5,0.6-0.9,1.1-1l24-4.5c1.1-0.2,2,0.8,1.7,1.9l-3.1,9.8c-0.2,0.5-0.6,0.9-1.1,1
			l-24,4.5C848.4,528.3,847.5,527.3,847.8,526.2z"
        />
        <Path
          style={{
            opacity: 0.3,
            fill: "none",
            stroke: "#000000",
            strokeMiterlimit: 10,
          }}
          d="M766.9,557.3l3.8-11.9c0.2-0.6,0.7-1.1,1.4-1.2l29.4-5.5c1.3-0.3,2.5,1,2.1,2.3l-3.8,11.9
			c-0.2,0.6-0.7,1.1-1.4,1.2l-29.4,5.5C767.6,559.9,766.5,558.6,766.9,557.3z"
        />
      </G>
      <G style={{ opacity: 7.0e-2 }}>
        <G id="Layer_3_2_">
          <Path
            style={{ fill: "#010101" }}
            d="M757.5-92.2c-157.1,0-284.4,127.3-284.4,284.4s127.3,284.4,284.4,284.4s284.4-127.3,284.4-284.4l0,0
				C1041.9,35.1,914.6-92.2,757.5-92.2z M554.8,297.9c-2.3-6.6-0.9-13.9,3.5-19.3L740.8,39.5c3-4,7.3-6.7,12.2-7.7v144L554.8,297.9z
				 M940.1,312.3c0,0-136.9-44.3-182.5-44.3S575,312.3,575,312.3c-5.8,0-11.4-2.3-15.4-6.4l197.9-122.1l197.9,122.1
				C951.4,310,945.9,312.4,940.1,312.3z M762,175.8v-144c4.9,1,9.2,3.7,12.2,7.7l182.5,239.1c4.5,5.4,5.8,12.7,3.5,19.3L762,175.8z"
          />
        </G>
      </G>
      <G>
        <G id="Layer_2_2_">
          <Path
            style={{ fill: "#010101" }}
            d="M362.4,51.3h8.5L397,90.4h0.1V51.3h6.5v47.6h-8.2l-26.5-39.1h-0.1v39.1h-6.5V51.3z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M429.5,90.9l-0.3,0.2c-0.6,0.4-1.2,0.8-1.8,1c-1.3,0.5-2.6,0.8-4,0.8c-1.4,0-2.7-0.3-4-0.8
				c-2.4-0.9-4.2-2.9-4.9-5.3c-0.2-0.4-0.3-0.8-0.4-1.3H426v-6h-11.8c0.1-0.3,0.2-0.6,0.3-0.9c0.4-1.2,1-2.3,1.9-3.2
				c0.8-0.9,1.9-1.6,3-2.1c1.3-0.5,2.6-0.8,4-0.8c1.4,0,2.8,0.2,4,0.8c0.6,0.3,1.2,0.6,1.8,1l0.3,0.2l3.4-5l-0.3-0.2
				c-0.9-0.6-1.8-1.1-2.8-1.5c-4.1-1.7-8.7-1.7-12.9,0c-3.9,1.6-7,4.7-8.7,8.6c-1.7,4-1.7,8.6,0,12.6c0.8,1.9,2,3.7,3.5,5.2
				c1.5,1.5,3.3,2.6,5.2,3.4c4.1,1.7,8.7,1.7,12.9,0c1-0.4,2-0.9,2.9-1.5l0.3-0.2L429.5,90.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M447.6,81.7l-10.9-15h7.8l7.7,11l7.6-11h7.3l-10.5,15l12.5,17.2h-7.8l-9.4-13.1l-9.4,13.1h-7.4L447.6,81.7z"
          />
          <Polygon
            style={{ fill: "#010101" }}
            points="496,66.6 470.4,66.6 470.4,72.8 480.2,72.8 480.2,98.8 486.3,98.8 486.3,72.8 496,72.8 			"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M519.1,51.3v27.9c0,1.6,0.2,3.1,0.6,4.6c0.4,1.6,1.1,3,2,4.4c1,1.4,2.2,2.5,3.7,3.3c1.5,0.8,3.4,1.3,5.7,1.3
				c2,0.1,3.9-0.4,5.6-1.3c1.5-0.8,2.7-1.9,3.7-3.3c0.9-1.3,1.6-2.8,2-4.4c0.4-1.5,0.6-3.1,0.6-4.6V51.3h6.5v28.9
				c0,2.6-0.4,5.2-1.4,7.6c-0.9,2.2-2.2,4.2-3.9,5.9c-1.7,1.7-3.7,3-5.9,3.8c-4.7,1.8-9.8,1.8-14.5,0c-2.2-0.8-4.2-2.1-5.8-3.8
				c-1.7-1.7-3-3.7-3.9-5.9c-1-2.4-1.5-5-1.4-7.6V51.3H519.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M553.6,98.8V66.7h11.5c1.6,0,3.1,0.1,4.7,0.4c1.3,0.3,2.6,0.8,3.7,1.5c1.1,0.7,1.9,1.7,2.5,2.8
				c0.6,1.2,0.9,2.7,0.9,4.5c0,1.6-0.2,3.1-0.8,4.5c-0.5,1.1-1.4,2.1-2.4,2.9c-1.1,0.7-2.3,1.3-3.6,1.5c-1.5,0.3-3.1,0.5-4.6,0.5
				h-5.7v13.4H553.6z M559.7,80.3h5.2c0.6,0,1.3-0.1,1.9-0.2c0.6-0.1,1.2-0.3,1.8-0.7c0.5-0.3,1-0.8,1.3-1.3
				c0.4-0.7,0.5-1.4,0.5-2.1c0-0.8-0.2-1.6-0.7-2.3c-0.4-0.6-1-1-1.6-1.3c-0.7-0.3-1.4-0.5-2.1-0.6l-2.1-0.1h-4.2V80.3z"
          />
        </G>
        <G id="Layer_3_1_">
          <Path
            style={{ fill: "#010101" }}
            d="M302.2,36.3c-20.5,0-37.2,16.6-37.2,37.2s16.6,37.2,37.2,37.2s37.2-16.6,37.2-37.2S322.7,36.3,302.2,36.3z
				 M275.7,87.3c-0.3-0.9-0.1-1.8,0.4-2.6L300,53.5c0.4-0.5,1-0.9,1.6-1v18.9L275.7,87.3z M326.1,89.1c0,0-17.9-5.8-23.9-5.8
				c-6,0-23.9,5.8-23.9,5.8c-0.7,0-1.5-0.3-2-0.8l25.9-16l25.9,16C327.6,88.8,326.8,89.1,326.1,89.1z M302.8,71.3V52.4
				c0.6,0.2,1.2,0.5,1.6,1l23.9,31.2c0.5,0.7,0.7,1.7,0.4,2.6L302.8,71.3z"
          />
        </G>
      </G>
      <Text
        transform="matrix(1 0 0 1 213.1541 201.0205)"
        letter-spacing={2}
        style={{ fontFamily: "OpenSans-ExtraBold", fontSize: 24 }}
      >
        CERTIFICATE OF COMPLETION
      </Text>
      <Text
        transform="matrix(1 0 0 1 330.7562 271.0522)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        This is to certify that
      </Text>
      <Text
        transform="matrix(1 0 0 1 116.6551 352.8164)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        has successfully completed the three-week Next Up Curated Event on
      </Text>
      <Text
        transform="matrix(1 0 0 1 223.0135 386.5811)"
        style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 20.166 }}
      >
        {props.user.course === "ML"
          ? "Fundamental Machine Learning "
          : "Creating Augmented Realities "}{" "}
      </Text>
      <Text
        transform="matrix(1 0 0 1 524.8826 386.5811)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        {" "}
        held from
      </Text>
      <Text
        transform="matrix(1 0 0 1 260.1927 420.3447)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        {moment(props.courseDate.start, "YYYY-MM-DD").format("MMMM Do")}
      </Text>
      {/* <Text transform="matrix(0.583 0 0 0.583 347.3948 413.6299)" style={{fontFamily:'OpenSans-Italic',fontSize:20.166}} >th</Text> */}
      <Text
        transform="matrix(1 0 0 1 407.992 420.3447)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        {" "}
        to
      </Text>
      <Text
        transform="matrix(1 0 0 1 435.992 420.3447)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        {moment(props.courseDate.end, "YYYY-MM-DD").format("MMMM Do")}
      </Text>
      {/* <Text transform="matrix(0.583 0 0 0.583 509.119 413.6299)" style={{fontFamily:'OpenSans-Italic',fontSize:20.166}} >th</Text> */}
      <Text
        transform="matrix(1 0 0 1 549.7156 420.3447)"
        style={{ fontFamily: "OpenSans-Italic", fontSize: 20.166 }}
      >
        {" "}
        {moment().format("YYYY")}.{" "}
      </Text>
      <Text transform="matrix(0.583 0 0 0.583 111.1713 535.0271)">
        <tspan
          x="0"
          y="0"
          style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 21.6255 }}
        >
          Resource Person
        </tspan>
        <tspan
          x="-2"
          y="20"
          style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 21.6255 }}
        >
          Fundamental ML
        </tspan>
      </Text>
      <Text transform="matrix(0.583 0 0 0.583 632.76 535.0271)">
        <tspan
          x="0"
          y="0"
          style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 21.6255 }}
        >
          CEO &amp; Chairman
        </tspan>
        <tspan
          x="42.6"
          y="20"
          style={{ fontFamily: "OpenSans-BoldItalic", fontSize: 21.6255 }}
        >
          Next Up
        </tspan>
      </Text>
      <Line
        style={{ fill: "none", stroke: "#000000", strokeMiterlimit: 10 }}
        x1="195.7"
        y1="322"
        x2="640.3"
        y2="322"
      />
      <Text
        transform="matrix(1 0 0 1 10.5127 587.5189)"
        style={{ fill: "#BCBEC0", fontSize: 6, fontFamily: "OpenSans-Regular" }}
      >
        verify at nextup.co.in/lookup
      </Text>
      <G>
        <G>
          <Path
            style={{ fill: "#010101" }}
            d="M375.2,507.9l19.2,11.8c0.2-0.7,0.1-1.4-0.3-1.9l-17.7-23.2c-0.3-0.4-0.7-0.7-1.2-0.8V507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M374.3,507.9v-14c-0.5,0.1-0.9,0.4-1.2,0.8l-17.7,23.2c-0.4,0.5-0.5,1.2-0.3,1.9L374.3,507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M374.7,508.6l-19.2,11.8c0.4,0.4,0.9,0.6,1.5,0.6c0,0,13.3-4.3,17.7-4.3c4.4,0,17.7,4.3,17.7,4.3
				c0.6,0,1.1-0.2,1.5-0.6L374.7,508.6z"
          />
        </G>
        <G>
          <Path
            style={{ fill: "#010101" }}
            d="M404.1,495.1h2.4l1.8,11.2h-1.8l-0.3-2.2v0h-2l-0.3,2.2h-1.6L404.1,495.1z M406,502.6l-0.8-5.5h0l-0.8,5.5
				H406z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M411.7,495.1h2.2l1.7,6.7h0v-6.7h1.6v11.2h-1.8l-2.1-8.2h0v8.2h-1.6L411.7,495.1L411.7,495.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M418.6,495.1h4.8v1.6h-3v3h2.4v1.6h-2.4v3.4h3v1.6h-4.8V495.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M425.9,500.6l-1.9-5.5h1.9l1.2,3.6h0l1.2-3.6h1.7l-1.9,5.5l2,5.7h-1.9l-1.3-3.9h0l-1.3,3.9h-1.7L425.9,500.6
				z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M432.3,496.7h-1.8v-1.6h5.4v1.6H434v9.6h-1.8L432.3,496.7L432.3,496.7z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M439.2,503.6v-8.5h1.8v8.7c0,0.8,0.3,1.1,0.9,1.1c0.6,0,0.9-0.3,0.9-1.1v-8.7h1.7v8.5c0,1.8-0.9,2.8-2.6,2.8
				C440.1,506.4,439.2,505.4,439.2,503.6z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M445.7,495.1h2.6c1.8,0,2.6,1,2.6,2.8v1.1c0,1.8-0.9,2.8-2.6,2.8h-0.8v4.6h-1.8L445.7,495.1L445.7,495.1z
				 M448.3,500.1c0.6,0,0.9-0.3,0.9-1.1v-1.3c0-0.8-0.3-1.1-0.9-1.1h-0.8v3.4L448.3,500.1z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M402.6,516.5v-6c0-1.7,0.9-2.8,2.6-2.8c1.7,0,2.6,1,2.6,2.8v1.2h-1.7v-1.3c0-0.7-0.4-1-0.9-1
				c-0.5,0-0.9,0.3-0.9,1v6.3c0,0.7,0.4,1,0.9,1c0.5,0,0.9-0.3,0.9-1v-1.7h1.7v1.6c0,1.7-0.9,2.8-2.6,2.8
				C403.5,519.2,402.6,518.2,402.6,516.5z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M408.8,516.4v-8.5h1.8v8.7c0,0.8,0.3,1.1,0.9,1.1c0.6,0,0.9-0.3,0.9-1.1v-8.7h1.7v8.5c0,1.8-0.9,2.8-2.6,2.8
				C409.7,519.2,408.8,518.2,408.8,516.4z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M415.3,507.9h2.6c1.8,0,2.6,0.9,2.6,2.6v0.7c0,1.2-0.4,1.9-1.2,2.2v0c0.9,0.3,1.2,1.1,1.2,2.4v2
				c0,0.5,0,0.9,0.2,1.4h-1.8c-0.1-0.3-0.2-0.5-0.2-1.4v-2c0-1-0.3-1.4-1.1-1.4h-0.6v4.8h-1.8L415.3,507.9L415.3,507.9z
				 M417.7,512.7c0.7,0,1.1-0.3,1.1-1.2v-0.9c0-0.8-0.3-1.2-0.9-1.2h-0.8v3.2H417.7z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M423.1,507.9h2.4l1.8,11.2h-1.8l-0.3-2.2v0h-2l-0.3,2.2h-1.6L423.1,507.9z M425,515.4l-0.8-5.5h0l-0.8,5.5
				H425z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M428.8,509.5H427v-1.6h5.4v1.6h-1.8v9.6h-1.8L428.8,509.5L428.8,509.5z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M433.3,507.9h4.8v1.6h-3v3h2.4v1.6H435v3.4h3v1.6h-4.8V507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M439.1,507.9h2.7c1.8,0,2.6,1,2.6,2.8v5.7c0,1.8-0.9,2.8-2.6,2.8h-2.7V507.9z M441.7,517.5
				c0.6,0,0.9-0.3,0.9-1.1v-5.8c0-0.8-0.3-1.1-0.9-1.1h-0.9v8L441.7,517.5L441.7,517.5z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M448.1,507.9h4.8v1.6h-3v3h2.4v1.6h-2.4v3.4h3v1.6h-4.8L448.1,507.9L448.1,507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M453.5,507.9h1.8l1.2,8.7h0l1.2-8.7h1.6l-1.7,11.2h-2.3L453.5,507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M460.1,507.9h4.8v1.6h-3v3h2.4v1.6h-2.4v3.4h3v1.6h-4.8V507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M465.9,507.9h2.2l1.7,6.7h0v-6.7h1.6v11.2h-1.8l-2.1-8.2h0v8.2h-1.6L465.9,507.9L465.9,507.9z"
          />
          <Path
            style={{ fill: "#010101" }}
            d="M474.1,509.5h-1.8v-1.6h5.4v1.6h-1.8v9.6h-1.8L474.1,509.5L474.1,509.5z"
          />
        </G>
      </G>
    </G>
    <G id="Layer_2">
      <Rect
        x="757.2"
        y="36.7"
        style={{ fill: "#FFFFFF" }}
        width="46"
        height="46"
      />
      {/* <CEOSign /> */}
      <G id="Param_Sign">
        <Path
          d="M170.3,511.5c0.1-0.4,0.1-0.7,0.3-1.1c0.1-0.3,0.4-0.6,0.6-0.9c0,0,0.1,0,0.1,0c0,0.5,0.1,1,0,1.4
			c-0.1,0.4-0.3,0.9-0.4,1.3c-0.2,0.5-0.3,0.6-0.8,0.4c-0.5-0.2-0.9-0.4-1.1-0.9c-0.1-0.2-0.3-0.5-0.5-0.7c-0.4-0.4-0.5-0.8-0.4-1.3
			c0-0.4-0.1-0.6-0.4-0.9c-0.7-0.6-1.2-1.3-1.9-1.9c-0.3-0.3-0.6-0.4-1-0.6c-0.7-0.4-1.3-0.8-2-1.2c-0.2-0.1-0.5-0.3-0.7-0.5
			c0,0.2,0,0.3,0,0.4c0,0.3,0,0.5,0,0.8c-0.1,0.5-0.1,1-0.3,1.5c-0.2,0.7-0.4,1.3-0.6,2c-0.1,0.3-0.2,0.7-0.3,1
			c-0.2,0.9-0.8,1.3-1.5,1.8c-0.3,0.2-0.5,0.4-0.4,0.8c0,0.3,0,0.5,0,0.8c0,0.4,0,0.8-0.3,0.9c-0.3,0.1-0.7,0-1,0
			c-0.3-0.1-0.6-0.2-0.8-0.4c-0.3-0.1-0.5-0.3-0.9-0.5c-0.1,0.4-0.2,0.9-0.3,1.3c-0.2,0.5-0.3,1.1-0.6,1.6c-0.2,0.4-0.8,0.5-1.2,0.4
			c-0.2,0-0.5-0.1-0.7-0.1c-0.4,0-0.7-0.2-0.9-0.6c-0.2-0.3-0.4-0.5-0.7-0.7c-0.5-0.4-1-0.6-1.4-1c-0.2-0.2-0.4-0.6-0.5-0.9
			c-0.2-0.3-0.4-0.6-0.7-0.9c-0.4-0.5-0.9-1-1.3-1.6c-0.1-0.1-0.2-0.3-0.3-0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.5,0,1.1-0.2,1.6
			c-0.3,1.2-0.6,2.3-1.2,3.4c-0.3,0.6-0.6,1.2-0.9,1.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0.2,0.4,0.4,0.7,0.6,1.1
			c0.2,0.3,0.4,0.5,0.2,0.8c-0.2,0.4-0.5,0.3-0.8,0.2c-0.5-0.2-0.7-0.6-0.9-1c-0.2-0.4-0.8-0.7-1.3-0.6c-0.7,0.2-1.3,0.1-2-0.1
			c-1.2-0.4-2.1-1.3-3.1-1.9c-0.4-0.3-0.9-0.5-1.4-0.7c0.1,0.6-0.3,1.2,0,1.8c0.1,0.2,0.1,0.5,0,0.7c-0.3,1-0.5,2.1-0.8,3.1
			c-0.1,0.3-0.1,0.6-0.2,0.9c-0.2,0.5-0.4,1.1-0.7,1.6c0,0.1-0.2,0.1-0.3,0.1c0.2,0.5,0,0.9-0.5,1c-0.9,0.2-1.8,0.6-2.8,0.4
			c-0.5-0.1-1-0.2-1.5-0.3c-1.5-0.5-2.4-1.6-3.4-2.8c-0.3-0.4-0.7-0.7-1-1.1c-0.3-0.3-0.6-0.6-0.6-1c0-0.2-0.1-0.5-0.2-0.7
			c0-0.4,0-0.8,0.5-1c0.5-0.2,1.5-0.3,1.9,0c-0.5,0.5-0.9,1-1.2,1.6c0,0,0.1,0.1,0.1,0.2c0.3,0.3,0.6,0.6,0.8,0.9
			c0.1,0.2,0.3,0.3,0.4,0.5c0,0,0.1,0.1,0.1,0.1c0.1,0.8,0.9,0.9,1.3,1.4c0.8,0.9,2,1.2,3.2,1.3c0.4,0.1,0.9-0.1,1.4-0.2
			c0.3-0.1,0.6-0.2,0.8-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.3,0.3-0.7,0.5-1c0.2-0.3,0.4-0.7,0.3-1.1c0-0.1,0-0.1,0-0.2
			c0.3-0.6,0.1-1.2,0.4-1.8c0.2-0.3,0.1-0.8,0.2-1.3c0-0.3,0.1-0.7,0.1-1c0-0.8-0.1-1.6-0.2-2.4c0-0.1-0.1-0.3-0.2-0.4
			c-0.7-0.5-1.1-1.3-1.7-1.9c-0.4-0.5-0.8-1.1-1.1-1.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.5-0.5-1.1-0.7-1.6c-0.4-1-0.8-2-1.2-2.9
			c-0.1-0.3-0.4-0.5-0.5-0.8c-0.4-0.7-0.3-1.6-0.5-2.4c-0.1-0.8-0.5-1.6-0.7-2.4c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3,0.2-0.5,0.4-0.7
			c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.3,0,0.4,0.1,0.6,0.3c0.2,0.3,0.6,0.6,1,0.7c0.4,0.1,0.6,0.5,0.8,0.8
			c0.4,0.4,0.7,0.9,1.1,1.4c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.2,0.6,0.6,0.1,1c0.3,0.4,0.2,1,0.8,1.3c0.1,0,0,0.3,0,0.4
			c0.1,0.5,0.3,1,0.4,1.5c0,0,0,0.1,0,0.1c0.2,0.4,0.2,0.4,0,0.7c0.1,0.4,0.2,0.8,0.3,1.1c0.1,0.3,0.2,0.5,0.3,0.8
			c0.3,0.6,0.5,1.3,0.8,1.9c0,0.1,0,0.2,0,0.3c0.1,0.5,0.1,1.1,0.2,1.6c0,0.1,0.2,0.1,0.3,0.1c0.3-0.1,0.5,0,0.6,0.3
			c0.2,0.5,0.4,0.9,0.6,1.3c0.1,0.1,0.3,0.2,0.4,0.2c0.8-0.1,1.3-0.7,1.7-1.4c0.1-0.1,0.1-0.3,0-0.4c-0.2-0.4-0.3-1-0.8-1.1
			c-0.1,0-0.2-0.2-0.2-0.3c-0.5-0.8-0.9-1.7-1.4-2.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.7-0.6-1.3-0.9-2
			c-0.2-0.4-0.4-0.7-0.5-1.1c0-0.4-0.3-0.9-0.5-1.3c-0.2-0.5-0.2-0.9-0.1-1.4c0.1-0.3,0.2-0.6,0.2-0.8c0-0.4,0.1-0.6,0.2-0.9
			c0.2-0.3,0.3-0.7,0.5-1c0.2-0.3,0.3-0.6,0.7-0.8c0.4-0.2,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.4,1.4-0.3c0.6,0,1-0.2,1.4-0.5
			c0.5-0.5,1-0.4,1.5-0.3c0.4,0.1,0.9,0.1,1.3,0.2c0.3,0,0.6,0.1,0.9,0.1c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.3,0.3-0.3
			c0.1,0,0.4,0.1,0.5,0.2c0.5,0.6,0.9,1.3,1.4,1.9c0.2,0.3,0.6,0.5,0.5,1c0,0.4,0.1,0.9,0.6,1.2c0.1,0,0.1,0.2,0.2,0.3
			c0,0.4,0.1,0.7,0.1,1.1c0,0.2,0,0.4,0.1,0.5c0.5,0.5,0.5,1.2,0.6,1.8c0.1,0.8,0.3,1.5,0.3,2.3c0,0.8,0,1.6-0.1,2.3
			c-0.1,0.6-0.1,1.2,0.1,1.8c0,0.1,0.1,0.1,0.1,0.2c0.3,0.4,0.5,0.8,0.8,1.2c0,0,0,0.1,0.1,0.1c0.5,0.5,1,1.1,1.5,1.6
			c0.3,0.3,0.5,0.7,0.7,1.1c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.4c0.7,0.6,1.3,1.3,2.2,1.7c0.5,0.2,0.8,0,0.9-0.5
			c0.1-0.4,0.1-0.8,0.2-1.1c0-0.2,0-0.4,0.1-0.5c0.3-0.4,0.3-0.8,0.2-1.2c-0.3-1.4-0.8-2.6-1.5-3.8c-0.3-0.5-0.6-0.9-0.9-1.4
			c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.7-0.3-1.3-0.8-1.8c0,0-0.1-0.1-0.1-0.2c0-0.3-0.1-0.6-0.1-0.9c0-0.1-0.2-0.1-0.3-0.2
			c0.2-0.5,0.5-0.6,0.9-0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.2,0.2,0.4-0.3,0.5c0.4,0.6,0.8,1.2,1.1,1.8c0.2,0.4,0.4,0.8,0.6,1.2
			c0.1,0.3,0.3,0.6,0.5,0.9c0.2,0.5,0.4,1,0.6,1.4c0.2,0.4,0.4,0.8,0.6,1.2c0.3,0.5,0.6,1,0.8,1.6c0.2,0.4,1,0.9,1.4,0.8
			c0.1,0,0.1-0.1,0.2-0.2c0.1-0.2,0.1-0.4,0.1-0.7c-0.2-0.1-0.4-0.1-0.5-0.2c-0.3-0.3-0.1-1,0.4-1.2c0.8-0.3,1.3-0.9,1.6-1.8
			c0.2-0.7,0.5-1.4,0.6-2.1c0.1-0.5,0.1-1.1,0.2-1.7c0-0.5,0-0.9,0-1.4c0-0.3-0.1-0.5-0.3-0.6c-0.7-0.4-1.5-0.8-2.3-1.2
			c-0.8-0.3-1.3-0.9-1.5-1.7c0-0.1,0-0.2,0-0.2c0.3-0.5,0.3-1.2,0.5-1.8c0.2-0.4,0.6-0.6,1-0.6c0.7,0.1,1.5,0.3,2.2,0.5
			c0.1,0,0.2,0.1,0.2,0.1c0.3,0.7,1,1.2,1,2.2c-0.1,0.6,0.1,1.1,0.2,1.7c0,0.1,0.1,0.2,0.2,0.3c0.3,0.3,0.5,0.7,0.8,0.9
			c0.5,0.4,1,0.7,1.5,1c0.4,0.2,0.8,0.4,1.1,0.7c0.8,0.6,1.5,1.2,2.2,1.8c0.4,0.4,0.7,0.8,1,1.3c0.2,0.3,0.3,0.8,0.3,1.2
			c0.1,0.7,0.6,1.1,0.9,1.6C170.2,511.5,170.3,511.5,170.3,511.5z M136,499c-0.3,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.4,0.3-0.5,0.6
			c-0.1,0.4-0.2,0.8-0.3,1.1c0,0.2,0,0.4,0.1,0.6c0.1,0.3,0.1,0.5,0.2,0.7c0.1,0.4,0.3,0.8,0.4,1.1c0.2,0.4,0.4,0.7,0.6,1.1
			c0.2,0.4,0.3,0.8,0.5,1.3c-0.1,0.2,0.1,0.4,0.4,0.7c0.1,0.1,0.3,0.3,0.4,0.4c0.4,0.4,0.7,0.9,1.2,1.3c0.2,0.2,0.4,0.3,0.4,0.6
			c0,0.2,0.1,0.4,0.2,0.6c0.2,0.3,0.6,0.6,0.9,0.6c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.3-0.6-0.8-1.1-0.7-1.8
			c0.1-0.5-0.2-0.9-0.5-1.3c-0.4-0.4-0.6-0.9-1-1.4c-0.2-0.3-0.4-0.6-0.6-0.7c-0.4-0.2-0.3-0.4-0.1-0.6c0.2-0.2,0.1-0.3-0.1-0.4
			c-0.4-0.2-0.7-0.4-0.8-0.9c-0.1-0.3-0.1-0.6,0.2-0.7c0.3-0.1,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.1,0.2,0.2c0.2,0.2,0.3,0.3,0.5,0.5
			c0.3,0.5,0.6,1,0.9,1.5c0.1,0.2,0.3,0.3,0.4,0.5c0.3,0.5,0.5,1.1,0.7,1.6c0.3,0.7,0.7,1.3,0.9,2c0.1,0.5,0.5,0.8,0.9,1.3
			c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.3,0.5-0.6,0.6-1c0.3-1,0.7-1.8,1.2-2.7c0.6-1.1,0.8-2.2,0.2-3.4c-0.5-1-0.8-2-0.7-3.1
			c0-0.1,0-0.1,0-0.2c0.2-0.7-0.1-1.2-0.5-1.8c-0.3-0.5-0.6-0.5-1-0.5c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.6-0.1-0.7,0.1
			c-0.7,0.4-1.3,0.7-2.1,0.7c-0.7,0-0.7,0-0.8,0.6c-0.7,0.1-1.4,0.3-1.4,1.1C135.6,498.9,135.7,498.9,136,499z M144,516.9
			c0.3-0.3,0.5-0.5,0.6-0.7c0.4-0.6,0.8-1.1,1-1.7c0.3-0.6,0.4-1.3,0.5-1.9c0.1-0.5,0.3-0.9,0.3-1.4c0-0.6-0.1-1.2-0.1-1.7
			c0-0.1,0-0.1-0.1-0.2c-0.6-1-1.1-1.9-1.7-2.9c-0.6,0.2-0.9,1.1-1,1.8c0,0.3-0.2,0.6-0.4,0.9c-0.4,0.5-0.7,1.1-0.9,1.7
			c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4,0.3-0.5,0.6-0.3,1c0.3,0.7,0.5,1.4,0.8,2c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.2,0.2,0.3,0.3
			C143.5,515.8,143.7,516.3,144,516.9z M129.9,499.7c-0.3,0.4-0.6,0.8-0.9,1.3c-0.2,0.2-0.3,0.5-0.1,0.8c0.1,0,0.3,0,0.4,0
			c0.4,0.4,0.7,0.8,0.7,1.3c0.1,1,0.2,1.9,0.7,2.8c0,0,0.1,0.1,0.1,0.2c-0.1,0.5,0.2,0.9,0.5,1.3c0.5,0.9,1,1.8,1.4,2.8
			c0.1,0.2,0.1,0.5,0.2,0.6c0.4,0.5,0.8,1,1.2,1.5c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0,0.1-0.1,0.2-0.1c-0.2-1-0.4-2-0.6-3
			c-0.2-0.9-0.5-1.7-0.7-2.6c-0.2-0.8-0.3-1.5-0.4-2.3c0-0.1,0-0.1,0-0.2c-0.2-0.6-0.5-1.1-0.7-1.7c-0.2-0.6-0.7-1-0.7-1.6
			c0-0.1-0.1-0.2-0.2-0.3C131.1,500.6,130.6,500.2,129.9,499.7z M136.1,513.6c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0.1,0.3
			c0.2,0.6,0.7,1.1,1.2,1.4c0.9,0.6,1.8,1.3,2.8,1.8c0.5,0.3,1.1,0.4,1.7,0.6c0.4,0.1,0.8-0.3,0.7-0.7c-0.1-0.4-0.3-0.8-0.5-1.2
			c-0.4-0.6-0.9-1.1-1.3-1.7c-0.3-0.5-0.4-0.5-0.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.2,0-0.2,0.1
			c-0.5,0.4-1.2,0.4-1.6-0.1C136.7,514.2,136.4,513.9,136.1,513.6z M160.9,502.4c0-0.6,0.1-1.1,0-1.6c-0.1-0.5-0.4-0.9-0.7-1.3
			c-0.1-0.1-0.4-0.1-0.5-0.1c-0.3,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.4,0.4-0.4,0.6c0,0.3,0,0.6,0.4,0.7c0.3,0.1,0.5,0.2,0.7,0.3
			C160,502,160.4,502.2,160.9,502.4z M146.5,507.1c0.4-1.8,0-3.5-0.4-5.3C145.1,503.5,145.5,506.4,146.5,507.1z M144.2,498.4
			c-0.3,0.7,0.3,2.9,0.7,3.2C145.2,501.3,144.7,498.7,144.2,498.4z"
        />
        <Path
          d="M178.5,497c0.4-0.1,0.9,0,1.1,0.4c0.2,0.3,0.3,0.6,0.4,1c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0,0.1,0,0.2,0c0-0.3,0-0.7-0.1-1
			c-0.2-0.5-0.1-1,0.2-1.4c0.1-0.2,0.3-0.4,0.5-0.7c0.3-0.4,0.6-0.5,1.1-0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.6,0,0.9,0.5,1.2,0.8
			c0.6,0.5,0.9,1.1,1.1,1.8c0.4,1,0.8,2,1.2,3c0,0.1,0.1,0.2,0.1,0.4c0,0.8,0.6,1.2,1.2,1.6c0.6,0.4,1.3,0.4,2,0.3
			c0.5-0.1,0.6-0.5,0.7-0.8c-0.4-0.3-0.4-1.3,0-1.6c0.1-0.1,0.1-0.5,0-0.6c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.4-0.2-0.8-0.4-1.1
			c-0.5-0.8-1-1.6-1.4-2.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.4,0-0.6c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0-0.1,0
			c-0.1-0.3-0.2-0.7-0.3-1c0-0.2-0.1-0.4-0.2-0.4c-0.4-0.2-0.3-0.4-0.2-0.7c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.4-0.2-0.5
			c0.4-0.1,0.7-0.2,0.9,0.2c0.1,0.2,0.4,0.3,0.5,0.5c0.2,0.3,0.3,0.7,0.4,1c0.2,0.6,0.5,1.2,0.7,1.8c0.2,0.5,0.3,1,0.4,1.5
			c0,0.1,0,0.2,0.1,0.3c0.8,1,1.7,2,3,2.4c0.5,0.2,1,0.4,1.5,0.7c0.5,0.2,0.8,0,0.8-0.5c0,0,0-0.1,0-0.1c0-0.2,0.1-0.4,0.1-0.6
			c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.3,0.3,0.6,0.2,1c-0.3,0.7-0.8,1.1-1.5,0.9c-0.7-0.2-1.4-0.5-2.1-0.8c-0.4-0.2-0.7-0.4-1-0.6
			c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.1,0.3-0.1,0.4c0,0.3,0,0.6,0,1c0.6,0.8,0.6,1.8,0.1,2.6c-0.3,0.4-0.7,0.5-1.2,0.5
			c-0.8,0-1.5,0.1-2.3-0.2c-0.4-0.2-0.7-0.4-1.1-0.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.4-0.2-0.6
			c-0.2-0.4,0.2-0.4,0.4-0.7c-0.2,0-0.3,0-0.5,0c-0.4-0.5-0.7-1.2-0.6-1.9c0-0.2-0.2-0.5-0.3-0.7c-0.3-0.4-0.5-0.9-0.9-1.3
			c-0.1-0.2-0.4-0.2-0.6-0.3c-0.4-0.1-0.7-0.3-1.2-0.4c0,0-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.3-0.2,1.9c0,0.2,0.1,0.4,0.1,0.6
			c0.1,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.2c0.4,0.7,0.8,1.4,1.2,2.1c0.2,0.3,0.5,0.6,0.6,1c0.1,0.5,0.2,1,0.2,1.5
			c0,0.1-0.2,0.4-0.3,0.4c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0.1-0.7-0.5-1-0.9-1.3c-0.5-0.2-0.7-0.5-0.7-1
			c0-0.7-0.2-1.3-0.7-1.8c0,0,0,0,0,0c-0.2-0.4-0.6-0.8-0.6-1.3c0-0.2,0-0.5-0.1-0.8c0-0.1,0-0.1-0.1-0.2
			C178.4,498.1,178.6,497.5,178.5,497z"
        />
        <Path
          d="M181.4,505.8c0.1,0,0.1,0.1,0.1,0.1c0.2,0.5-0.1,1.4-0.5,1.7c-0.7,0.5-1.1,0-1.6-0.3c-0.6-0.4-1.3-0.8-2.1-1.3
			c0,0.3-0.1,0.5-0.1,0.7c0.2,0.8-0.4,1.3-0.8,1.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.6,1-1.6,0.9-2.6,0.9c-0.2,0-0.4-0.3-0.6-0.5
			c-0.2-0.2-0.3-0.3-0.5-0.5c-0.9-0.5-1.7-1.1-2.1-2.1c-0.2-0.7-0.4-1.5-0.6-2.3c-0.1-0.4-0.2-0.9-0.3-1.3c-0.1-0.5-0.2-1-0.2-1.5
			c0-0.2,0-0.5,0-0.7c0-0.4,0.1-0.9,0.2-1.3c0.2-0.7,0.3-1.4,0.7-2c0.4-0.6,1.1-1.1,1.8-1.4c0.7-0.3,1.4,0,2,0.5
			c0.9,0.8,1.4,2,1.9,3c0.1,0.1,0.1,0.3,0.2,0.4c0.5,0.4,0.5,1,0.5,1.6c-0.1,0.6,0.3,1,0.6,1.4c0.2,0.3,0.4,0.6,0.5,1
			c0.2,0.9,0.8,1.3,1.4,1.8c0.3,0.2,0.6,0.5,0.9,0.7c0.4,0.4,0.8,0.3,1-0.2C181.1,506.1,181.3,505.9,181.4,505.8z M176.5,506.7
			c-0.5-0.4-0.3-0.8-0.1-1.3c0.3-0.7,0-1.3-0.4-1.9c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.2-0.2-0.4-0.2-0.6
			c0-0.3,0-0.6,0-0.7c-0.1-0.2-0.3-0.3-0.3-0.4c0.2-0.4,0-0.8-0.2-1.2c-0.2-0.4-0.4-0.8-0.6-1c-0.5-0.4-1.1-0.6-1.8-0.7
			c0,0.5-0.2,0.9-0.7,1.2c-0.2,0.1-0.2,0.3-0.3,0.5c-0.2,0.3-0.3,0.6-0.5,0.9c-0.3,0.3-0.5,0.5,0,0.8c-0.1,0.1-0.1,0.1-0.1,0.1
			c-0.4,0.2-0.3,0.5-0.3,0.8c0,0.3-0.1,0.6,0.4,0.6c0.2,0,0.2,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
			c0.2,1,0.4,2.1,0.7,3.1c0.1,0.5,0.5,0.8,0.9,1.1c0.5,0.4,1,0.7,1.4,1c0.5,0.4,1.5,0.4,1.8-0.1
			C175.8,507.7,176.1,507.2,176.5,506.7z M176.5,506.5c0,0,0.1,0,0.1,0c0.1-0.2,0.1-0.3,0.2-0.5c0,0-0.1-0.1-0.2-0.2
			c0,0.1-0.1,0.1-0.1,0.2C176.5,506.2,176.5,506.3,176.5,506.5z"
        />
        <Path
          d="M151.9,497.9c-0.3,0.4-0.7,0.3-1,0c-0.3-0.2-0.6-0.3-0.9-0.2c-0.4,0.1-0.8-0.1-1.1-0.5c-0.4-0.6-0.3-1.2-0.1-1.8
			c0-0.2,0.4-0.3,0.7-0.3c0.2-0.1,0.5-0.1,0.6-0.1c0.6,0.2,0.5,1,0.9,1.4C151.4,496.9,151.8,497.4,151.9,497.9z"
        />
        <Path
          d="M186.4,486.1c-0.3-0.2-0.5-0.3-0.7-0.4c0.2-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.5-0.1,0.8,0c0.5,0.3,0.8,1,0.6,1.6
			c-0.1,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.3,0.4-0.4,0.4c-0.2,0-0.4-0.1-0.4-0.5c0-0.2-0.1-0.5-0.2-0.7
			C186.5,486.4,186.4,486.3,186.4,486.1z"
        />
      </G>

      {/* <image style="overflow:visible;" width="100" height="100" id="QR" xlink:href="qr_ml04/CR-000086.png"  transform="matrix(0.4046 0 0 0.4046 760.0161 39.4385)">
	</image> */}
      <Text
        id="Name"
        textAnchor="middle"
        transform="matrix(1 0 0 1 420.4948 312.3379)"
        style={{ fontFamily: "OpenSans-Regular", fontSize: 22.4682 }}
      >
        {props.user.name}
      </Text>
    </G>
  </Svg>
);
function Certificate(props) {
  if (!props.location.state) window.location.href = "/lookup";

  const [courseDate, setCourseDate] = useState();
  const user = props.location.state.user;
  const purpose = props.location.state.purpose;
  const db = useContext(DB);

  const index = purpose.includes("Course") ? 2 : 3;

  useEffect(() => {
    db.getDatabase("/courses/ml/events", (d) => {
      setCourseDate(d.slice(-1)[0].date);
    });
  }, [db]);
  
  const getCanvasURIPromise = () => {
    return new Promise((resolve, reject) => {
      const canvas = document.querySelector(".HpQrcode > canvas");
      if (canvas)
        resolve(Buffer.from(canvas.toDataURL().split("base64,")[1], "base64"));
      else reject("");
    });
  };

  return (
    <Fragment>
      <div className="HpQrcode" style={{ display: "none" }}>
        {/* <QRCode value='1.0/CR-000100/20210924/Qn2x8BqP' /> */}
        <QRCode
          value={`1.0/${user.documents[index].id}/${moment(user.documents[index].date, "YYYY-MM-DD").format("YYYYMMDD")}/${user.documents[
            index
          ].url.substring(10, 18)}`}
        />
      </div>
      <PDFViewer style={{ height: window.innerHeight, width: "100%" }}>
        <Document>
          <Page size="A4" className="page" orientation="landscape">
            {courseDate && (
              <View>
                {purpose.includes("Course") ? (
                  <CompletionCertificate user={user} courseDate={courseDate} />
                ) : (
                  <InternCertificate user={user} courseDate={courseDate} />
                )}
              </View>
            )}
            {purpose.includes("Course") ? (
              <View style={{ position: "absolute", top: 40, right: 40 }}>
                <Image
                  src={async () => await getCanvasURIPromise()}
                  style={{ height: 42, width: 42 }}
                />
              </View>
            ) : (
              <View style={{ position: "absolute", top: 75, right: 75 }}>
                <Image
                  src={async () => await getCanvasURIPromise()}
                  style={{ height: 42, width: 42 }}
                />
              </View>
            )}

            {purpose.includes("Course") && (
              <Image
                src={require("./images/doc/Sign21.png")}
                style={{ width: 75, top: 478, left: 115 }}
              ></Image>
            )}
            <Image
              src={require("./images/doc/Sign20.png")}
              style={[
                { height: 80, width: 105 },
                purpose.includes("Course")
                  ? { top: 465, right: 100, position: "absolute" }
                  : { top: 438, left: 78 },
              ]}
            ></Image>
          </Page>
        </Document>
      </PDFViewer>
    </Fragment>
  );
}
export default Certificate;
