import React from "react";
import "./Circles.css";

export const CircleContainer = (props) => (
  <div class="single-chart">
    <svg viewBox="0 0 36 36" class="circular-chart blue">
      <path
        class="circle-bg"
        d="M18 5.268
            a 12.7324 12.7324 0 0 1 0 25.464
            a 12.7324 12.7324 0 0 1 0 -25.464"
      />
      <path
        class="circle"
        stroke-dasharray="74, 80"
        d="M18 5.268
            a 12.7324 12.7324 0 0 1 0 25.464
            a 12.7324 12.7324 0 0 1 0 -25.464"
      />
      <text x="18" y="20.35" class="percentage">
        94%
      </text>
    </svg>
    <div className="circle-title">
      Likelihood of Recommending to A Friend or Colleague
    </div>
  </div>
);
