import { Header } from "./Components";
import React from "react";

function Marketing() {
  return (
    <div>
      <Header />
    </div>
  );
}

export default Marketing;
