import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router";
import "./Cards.css";
import { Header, Sitemap } from "./Components";
import "./Components.css";
import DB from "./DB";

function AR(props) {
  const db = useContext(DB);

  const [ar, setar] = useState();
  const [redirect, setRedirect] = useState();
  const [redirectProps, setRedirectProps] = useState();

  useEffect(() => {
    db.getDatabase("/courses/ar", (data) => {
      console.log("data", data);
      setar(data);
    });
  }, [db]);

  // const get = async () => {
  // 	console.log('get')
  // 	console.log(await db.getCourse('ar'))
  // }
  if (redirect) return <Redirect push to={{ pathname: redirect, state: redirectProps }} />;

  let borderColour = ["#f15331", "#992964"];
  let upcomingTop = ["#ff6545", "#de3a90"];
  let upcomingBg = ["#d14224", "#992964"];
  let icon = ["\uf3fd", "\uf7b6"];

  return (
    <div className="arMainDiv">
      <Header />
      {ar && (
        <div>
          <div
            className="mainCard"
            style={{
              backgroundImage: `url(${require("./images/ar/bg.png")})`,
              backgroundAttachment: "fixed",
              backgroundPosition: "0% 3%",
              backgroundSize: "120%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img src={require("./images/ar/logo.png")} className="arTitle fadeBottom" alt="Creating Augmented Realities" />
          </div>
          <br />

          <div className="info">
            <h3>{ar.description[0].title}</h3>
            <span>{ar.description[0].content[0]}</span>
            <hr />
            <h3>{ar.description[1].title}</h3>
            <span>{ar.description[1].content[0]}</span>
          </div>

          {borderColour.map((item, i) => (
            <div className="card-container" key={i}>
              <div class="flip-card">
                <h3>
                  <b className="fas">{`${icon[i]}`}</b> {ar.cards[i].title}
                </h3>
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ borderTop: ` 9px solid ${item}` }}>
                    <span>
                      {ar.cards[i].content.map((item, i) => (
                        <li>{item}</li>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div class="upcomingEvents">
            <h3>
              <b className="fas">{"\uf1da"}</b>&nbsp;&nbsp;Upcoming Events
            </h3>
          </div>

          {upcomingTop.map((item, i) => (
            <div class="upcoming">
              <div
                className="eventTitle"
                style={{
                  borderTop: `10px solid ${item}`,
                  background: `${upcomingBg[i]}`,
                }}
              >
                <div className="event">
                  <h3>
                    <b className="fas">{`${icon[i]}`}</b> {ar.events[i].title}
                  </h3>
                </div>
                <div className="dateBox">
                  <span>
                    <b className="fas">{"\uf073"}</b> &nbsp; March 14 - 15 , 2023
                  </span>
                </div>
              </div>
              <div className="eventDetail">
                <div>
                  <span>
                    <b className="fas">{"\uf3c5"}</b>&nbsp; {ar.events[i].location.address}
                  </span>
                </div>
                <div className="publicEvent">
                  <span>
                    <b className="fas">{"\uf007"}</b>&nbsp; {ar.events[i].entry}
                  </span>
                </div>
                <div style={{ display: "inline-block" }}>
                  <span>
                    <b className="fas">{"\uf0c0"}</b>&nbsp; {ar.events[i].seats}
                  </span>
                </div>
                <div>
                  <span>
                    <b className="fas">{"\uf017"}</b>&nbsp; 8:30 AM - 4:30 PM
                  </span>
                </div>
              </div>
              <div
                className="registerButton"
                onClick={() => {
                  setRedirect("/register");
                  setRedirectProps({
                    title: `${ar.events[i].title}`,
                    course: "ar",
                    id: 0,
                  });
                }}
              >
                REGISTER
              </div>
            </div>
          ))}
        </div>
      )}

      <Sitemap />
    </div>
  );
}

export default AR;
